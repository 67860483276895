import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnauthenticatedChangePasswordDTO } from '../../generated/api-client';

export interface ResetPasswordState {
  loading: boolean;
}

const resetPasswordInitialState: ResetPasswordState = {
  loading: false,
};

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState: resetPasswordInitialState,
  reducers: {
    requestResetPassword(
      state,
      action: PayloadAction<UnauthenticatedChangePasswordDTO>
    ) {
      state.loading = true;
    },
    requestResetPasswordSuccess(state) {
      state.loading = false;
    },
    requestResetPasswordFailure(state) {
      state.loading = false;
    },
  },
});

export const resetPasswordActions = resetPasswordSlice.actions;

const resetPasswordReducer = resetPasswordSlice.reducer;

export default resetPasswordReducer;
