import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useLoaderStyles } from "./styles";

interface LoaderProps {
  open: boolean;
}

export default function Loader({ open }: LoaderProps) {
  const [height, setHeight] = useState(100);
  const classes = useLoaderStyles();

  useEffect(() => {
    setHeight(window.innerHeight / 2);
  }, []);
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress size={height} />
    </Backdrop>
  );
}
