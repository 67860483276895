import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { VotingSystem } from '../../../../../generated/api-client';
import homeSelectors from '../../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { homeActions } from '../../../slice';
import useUserPlan from '../../../../../hooks/useUserPlan';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
      marginTop: theme.spacing(2),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function Index() {
  const votingSystems = useSelector(homeSelectors.selectVotingSystems);
  const tableForm = useSelector(homeSelectors.selectTableForm);
  const { hasCustomPointingScales } = useUserPlan();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value === '') {
      dispatch(homeActions.setTableFormSelectedVotingSystem(null));
    } else {
      dispatch(
        homeActions.setTableFormSelectedVotingSystem(event.target.value)
      );
    }
  };

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth>
      <InputLabel id="demo-simple-select-outlined-label">
        Voting System
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={tableForm.selectedVotingSystem || ''}
        onChange={handleChange}
        label="Voting System"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {hasCustomPointingScales &&
          votingSystems.owned.map((system: VotingSystem) => (
            <MenuItem value={system._id} key={system._id}>{`${
              system.name
            } ( ${system.options.join(', ')} )`}</MenuItem>
          ))}

        {votingSystems.adminOwned.map((system: VotingSystem) => (
          <MenuItem value={system._id} key={system._id}>{`${
            system.name
          } ( ${system.options.join(', ')} )`}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
