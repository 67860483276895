/* tslint:disable */
/* eslint-disable */
/**
 * API
 * The API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangePasswordDto } from '../model';
// @ts-ignore
import { ForgotPasswordDto } from '../model';
// @ts-ignore
import { LoginDto } from '../model';
// @ts-ignore
import { LoginResponse } from '../model';
// @ts-ignore
import { SignupDto } from '../model';
// @ts-ignore
import { SignupResponse } from '../model';
// @ts-ignore
import { UnauthenticatedChangePasswordDTO } from '../model';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (changePasswordDto: ChangePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordDto' is not null or undefined
            assertParamExists('changePassword', 'changePasswordDto', changePasswordDto)
            const localVarPath = `/api/auth/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordDto: ForgotPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordDto' is not null or undefined
            assertParamExists('forgotPassword', 'forgotPasswordDto', forgotPasswordDto)
            const localVarPath = `/api/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('login', 'loginDto', loginDto)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignupDto} signupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (signupDto: SignupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signupDto' is not null or undefined
            assertParamExists('signup', 'signupDto', signupDto)
            const localVarPath = `/api/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnauthenticatedChangePasswordDTO} unauthenticatedChangePasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unauthenticatedChangePassword: async (unauthenticatedChangePasswordDTO: UnauthenticatedChangePasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unauthenticatedChangePasswordDTO' is not null or undefined
            assertParamExists('unauthenticatedChangePassword', 'unauthenticatedChangePasswordDTO', unauthenticatedChangePasswordDTO)
            const localVarPath = `/api/auth/unauthenticated-change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unauthenticatedChangePasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(changePasswordDto: ChangePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(changePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignupDto} signupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(signupDto: SignupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(signupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UnauthenticatedChangePasswordDTO} unauthenticatedChangePasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unauthenticatedChangePassword(unauthenticatedChangePasswordDTO: UnauthenticatedChangePasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unauthenticatedChangePassword(unauthenticatedChangePasswordDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePasswordDto: ChangePasswordDto, options?: any): AxiosPromise<object> {
            return localVarFp.changePassword(changePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.forgotPassword(forgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginDto: LoginDto, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignupDto} signupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(signupDto: SignupDto, options?: any): AxiosPromise<SignupResponse> {
            return localVarFp.signup(signupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnauthenticatedChangePasswordDTO} unauthenticatedChangePasswordDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unauthenticatedChangePassword(unauthenticatedChangePasswordDTO: UnauthenticatedChangePasswordDTO, options?: any): AxiosPromise<object> {
            return localVarFp.unauthenticatedChangePassword(unauthenticatedChangePasswordDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changePassword operation in AuthApi.
 * @export
 * @interface AuthApiChangePasswordRequest
 */
export interface AuthApiChangePasswordRequest {
    /**
     * 
     * @type {ChangePasswordDto}
     * @memberof AuthApiChangePassword
     */
    readonly changePasswordDto: ChangePasswordDto
}

/**
 * Request parameters for forgotPassword operation in AuthApi.
 * @export
 * @interface AuthApiForgotPasswordRequest
 */
export interface AuthApiForgotPasswordRequest {
    /**
     * 
     * @type {ForgotPasswordDto}
     * @memberof AuthApiForgotPassword
     */
    readonly forgotPasswordDto: ForgotPasswordDto
}

/**
 * Request parameters for login operation in AuthApi.
 * @export
 * @interface AuthApiLoginRequest
 */
export interface AuthApiLoginRequest {
    /**
     * 
     * @type {LoginDto}
     * @memberof AuthApiLogin
     */
    readonly loginDto: LoginDto
}

/**
 * Request parameters for signup operation in AuthApi.
 * @export
 * @interface AuthApiSignupRequest
 */
export interface AuthApiSignupRequest {
    /**
     * 
     * @type {SignupDto}
     * @memberof AuthApiSignup
     */
    readonly signupDto: SignupDto
}

/**
 * Request parameters for unauthenticatedChangePassword operation in AuthApi.
 * @export
 * @interface AuthApiUnauthenticatedChangePasswordRequest
 */
export interface AuthApiUnauthenticatedChangePasswordRequest {
    /**
     * 
     * @type {UnauthenticatedChangePasswordDTO}
     * @memberof AuthApiUnauthenticatedChangePassword
     */
    readonly unauthenticatedChangePasswordDTO: UnauthenticatedChangePasswordDTO
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {AuthApiChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public changePassword(requestParameters: AuthApiChangePasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).changePassword(requestParameters.changePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiForgotPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public forgotPassword(requestParameters: AuthApiForgotPasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).forgotPassword(requestParameters.forgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(requestParameters: AuthApiLoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(requestParameters.loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiSignupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signup(requestParameters: AuthApiSignupRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).signup(requestParameters.signupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiUnauthenticatedChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public unauthenticatedChangePassword(requestParameters: AuthApiUnauthenticatedChangePasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).unauthenticatedChangePassword(requestParameters.unauthenticatedChangePasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
