import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Fab, Grid, useTheme } from "@mui/material";
import { useAppDispatch } from "../../../../init/hooks";
import { Add } from "@mui/icons-material";
import { homeActions } from "../../slice";
import homeSelectors from "../../selectors";
import { useSelector } from "react-redux";
import OptionsInput from "./OptionsInput";

export default function CreateVotingSystemModal() {
  const [canSubmit, setCanSubmit] = useState(false);
  const theme = useTheme();

  const open = useSelector(homeSelectors.selectVotingSystemModalOpen);
  const formValues = useSelector(homeSelectors.selectVotingSystemModal);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(homeActions.setVotingSystemModalOpen({ open: false }));
    dispatch(homeActions.resetVotingSystemModalFormValues());
  };

  useEffect(() => {
    let res = true;
    if (formValues.name.length === 0) res = false;
    if (formValues.options.length < 2) res = false;

    formValues.options.forEach((option: string) => {
      if (option.length === 0) res = false;
    });

    setCanSubmit(res);
  }, [formValues.options]);

  const handleCreateSubmit = () => {
    dispatch(homeActions.requestCreateVotingSystem());
  };

  const handleEditSubmit = () => {
    if (formValues.id === null) return;
    dispatch(
      homeActions.requestEditVotingSystem({
        _id: formValues.id,
        name: formValues.name,
        options: formValues.options,
      })
    );
  };

  const handleNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(homeActions.setVotingSystemModalName(e.target.value));
  };

  const handleAddOption = () => {
    dispatch(homeActions.setVotingSystemModalAddOption());
  };

  const renderSubmitButton = () => {
    if (formValues.id) {
      return (
        <Button
          onClick={handleEditSubmit}
          variant="contained"
          color="primary"
          disabled={!canSubmit}
        >
          Edit
        </Button>
      );
    } else {
      return (
        <Button
          onClick={handleCreateSubmit}
          variant="contained"
          color="primary"
          disabled={!canSubmit}
        >
          Create
        </Button>
      );
    }
  };

  const handleDelete = () => {
    if (formValues.id) {
      dispatch(homeActions.requestDeleteVotingSystem(formValues.id));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {formValues.id ? "Edit Voting System" : "Create New Voting System"}
      </DialogTitle>
      <DialogContent>
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <TextField
              sx={{ marginY: theme.spacing(1) }}
              value={formValues.name}
              onChange={handleNameChange}
              label="Voting System Name"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <OptionsInput />
        <Grid
          container
          justifyContent="center"
          sx={{ marginTop: theme.spacing(1) }}
        >
          <Grid item>
            <Fab size="small" onClick={handleAddOption}>
              <Add />
            </Fab>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            {formValues.id && (
              <Button onClick={handleDelete} color="secondary">
                Delete
              </Button>
            )}
          </Grid>
          <Grid>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            {renderSubmitButton()}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
