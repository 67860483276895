import { call, put, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { RootState } from '../../global/redux/store';
import { ApiClient } from '../../global/apiClient';
import { PayloadAction } from '@reduxjs/toolkit';
import { RequestAddTableUserPayload, tableActions } from './slice';
import {
  AddTableUserDto,
  Table,
  UpdateTableUserVoteDto,
  VotingSystem,
} from '../../generated/api-client';
import { RemoveTableUserDto } from '../../generated/api-client/model/remove-table-user-dto';
import {
  notificationActions,
  NotificationVariantEnum,
} from '../../components/Notification/slice';
import { GetTableOwnerDetailsResponse } from '../../generated/api-client/model/get-table-owner-details-response';

const { setNotification } = notificationActions;

export function* getTableSaga({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<Table> = yield call(
      ApiClient.table.getOne,
      payload
    );

    const { data } = res;

    yield put(tableActions.requestGetTableSuccess(data));
    yield put(tableActions.requestVotingSystemDetails(data.votingSystem));
    yield put(tableActions.requestTableOwnerPlan(data.owner));
  } catch (e) {
    yield put(tableActions.requestGetTableFailure());
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error getting the table details',
      })
    );
  }
}

export function* addTableUserSaga({
  payload,
}: PayloadAction<RequestAddTableUserPayload>) {
  try {
    const tableId: string = yield select(
      (state: RootState) => state.table.tableData._id
    );
    const browserId: string = yield select(
      (state: RootState) => state.auth.guestUser.browserId
    );
    const reqObj: AddTableUserDto = {
      name: payload.name,
      _id: tableId,
      browserId,
      type: payload.type,
    };

    const res: AxiosResponse<Table> = yield call(
      ApiClient.table.addUser,
      reqObj
    );

    yield put(tableActions.setTableData(res.data));
  } catch {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error adding you to this table',
      })
    );
  }
}

export function* getVotingSystemDetailsSaga({
  payload,
}: PayloadAction<string>) {
  try {
    const res: AxiosResponse<VotingSystem> = yield call(
      ApiClient.votingSystem.getOne,
      payload
    );

    yield put(tableActions.requestVotingSystemDetailsSuccess(res.data));
  } catch (e) {
    yield put(tableActions.requestVotingSystemDetailsFailure());
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error getting the voting system for this table',
      })
    );
  }
}

export function* updateUserVoteSaga({ payload }: PayloadAction<string | null>) {
  try {
    const tableId: string = yield select(
      (state: RootState) => state.table.tableData._id
    );
    const browserId: string = yield select(
      (state: RootState) => state.auth.guestUser.browserId
    );
    const requestObj: UpdateTableUserVoteDto = {
      _id: tableId,
      browserId,
      vote: payload,
    };

    const res: AxiosResponse<Table> = yield call(
      ApiClient.table.updateUserVote,
      requestObj
    );

    yield put(tableActions.setTableData(res.data));
  } catch (e) {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error updating your vote',
      })
    );
  }
}

export function* flipCardsSaga() {
  try {
    const _id: string = yield select(
      (state: RootState) => state.table.tableData._id
    );

    const res: AxiosResponse<Table> = yield call(
      ApiClient.table.flipCards,
      _id
    );

    yield put(tableActions.setTableData(res.data));
  } catch (e) {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error flipping the cards',
      })
    );
  }
}

// Probably don't actually need this. When a user leaves, the websocket will disconnect - triggering an update to this field
export function* leaveTableSaga() {
  try {
    const tableId: string = yield select(
      (state: RootState) => state.table.tableData._id
    );
    const browserId: string = yield select(
      (state: RootState) => state.auth.guestUser.browserId
    );

    const reqObj: RemoveTableUserDto = {
      tableId,
      browserId,
    };

    const res: AxiosResponse<Table> = yield call(
      ApiClient.table.removeUser,
      reqObj
    );

    yield put(tableActions.setTableData(res.data));
  } catch (e) {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error removing a user from the table',
      })
    );
  } finally {
    yield put(tableActions.setLeaveRequestPending(false));
  }
}

export function* startNewVoteSaga() {
  try {
    const _id: string = yield select(
      (state: RootState) => state.table.tableData._id
    );

    const res: AxiosResponse<Table> = yield call(
      ApiClient.table.startNewVote,
      _id
    );

    yield put(tableActions.setTableData(res.data));
  } catch {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error starting the new vote',
      })
    );
  }
}

export function* resetActiveUsersSaga({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<Table> = yield call(
      ApiClient.table.resetActiveUsers,
      payload
    );

    yield put(
      notificationActions.setNotification({
        variant: NotificationVariantEnum.Success,
        message: 'Voters reset successfully',
      })
    );
    yield put(tableActions.setTableData(res.data));
  } catch {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error booting those users',
      })
    );
  }
}

export function* removeTableUserSaga({
  payload,
}: PayloadAction<RemoveTableUserDto>) {
  try {
    const res: AxiosResponse<Table> = yield call(
      ApiClient.table.removeUser,
      payload
    );

    yield put(
      notificationActions.setNotification({
        variant: NotificationVariantEnum.Success,
        message: 'User removed successfully',
      })
    );
    yield put(tableActions.setTableData(res.data));
  } catch {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'Something went wrong with removing this user',
      })
    );
  }
}

export function* requestTableOwnerPlanSaga({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<GetTableOwnerDetailsResponse> = yield call(
      ApiClient.user.getTableOwnerPlan,
      payload
    );

    yield put(tableActions.requestTableOwnerPlanSuccess(res.data));
  } catch {
    yield put(tableActions.requestTableOwnerPlanFailure());
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: "Something went wrong with getting the table owner's plan",
      })
    );
  }
}

export function* tableSagaWatcher() {
  yield takeLatest(tableActions.requestGetTable, getTableSaga);
  yield takeLatest(tableActions.requestAddTableUser, addTableUserSaga);
  yield takeLatest(
    tableActions.requestVotingSystemDetails,
    getVotingSystemDetailsSaga
  );
  yield takeLatest(tableActions.requestUpdateUserVote, updateUserVoteSaga);
  yield takeLatest(tableActions.requestFlipCards, flipCardsSaga);
  yield takeLatest(tableActions.requestLeaveTable, leaveTableSaga);
  yield takeLatest(tableActions.requestStartNewVote, startNewVoteSaga);
  yield takeLatest(tableActions.requestResetActiveUsers, resetActiveUsersSaga);
  yield takeLatest(tableActions.requestRemoveUser, removeTableUserSaga);
  yield takeLatest(
    tableActions.requestTableOwnerPlan,
    requestTableOwnerPlanSaga
  );
}

export default tableSagaWatcher;
