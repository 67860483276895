export enum PageTitles {
  Table = 'Agile Poker | Table',
  Home = 'Agile Poker | Home',
  Login = 'Agile Poker | Login',
  Signup = 'Agile Poker | Signup',
  Profile = 'Agile Poker | Profile',
  ForgotPassword = 'Agile Poker | Forgot Password',
  ResetPassword = 'Agile Poker | Reset Password',
}

export enum Plans {
  Free,
  Basic,
  Plus,
}
