import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { IconButton, ListSubheader } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useSelector } from "react-redux";
import tableSelectors from "../../selectors";
import _ from "lodash";
import { useAppDispatch } from "../../../../init/hooks";
import { tableActions } from "../../slice";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export default function SpectatorList() {
  const spectators = useSelector(tableSelectors.selectSpectators);
  const dispatch = useAppDispatch();
  const tableId = useSelector(tableSelectors.selectTableId);
  const isTableOwner = useSelector(tableSelectors.selectIsTableOwner);

  const handleRemoveUser = (browserId: string) => {
    dispatch(tableActions.requestRemoveUser({ tableId, browserId }))
  }

  return (
    <Box>
      <List
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader id="nested-list-subheader">Spectators</ListSubheader>
        }
      >
        {spectators.length > 0 ? (
          spectators.map((user) => (
            <ListItem key={user.browserId}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primary={_.startCase(user.name)}
                sx={{ overflowWrap: "anywhere" }}
              />
              {isTableOwner && (
                <IconButton
                  size="small"
                  onClick={() => handleRemoveUser(user.browserId)}
                  color="inherit"
                >
                  <RemoveCircleOutlineIcon fontSize="small" />
                </IconButton>
              )}
            </ListItem>
          ))
        ) : (
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="None" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );
}
