import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { AddTableUserDtoTypeEnum, LoginDto } from '../../generated/api-client';
import { Plans } from '../../global/enums';
import { profileActions } from '../Profile/slice';

export interface SetAuthPayload {
  email?: string;
  token?: string;
  _id?: string;
  firstName?: string;
  lastName?: string;
  plan?: number;
}

export interface Facilitator {
  email: string;
  token: string;
  firstName?: string;
  lastName?: string;
  plan: number;
  _id: string;
}

export interface GuestUser {
  name: string;
  browserId: string;
  type: AddTableUserDtoTypeEnum;
}

export interface AuthState {
  facilitator: Facilitator;
  guestUser: GuestUser;
  authLoading: boolean;
}

const authInitialState: AuthState = {
  facilitator: {
    email: '',
    token: '',
    _id: '',
    plan: Plans.Free,
  },
  guestUser: {
    name: '',
    browserId: '',
    type: AddTableUserDtoTypeEnum.Participant,
  },
  authLoading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    logout() {
      return authInitialState;
    },
    setAuth(state, { payload }: PayloadAction<SetAuthPayload>) {
      return {
        ...state,
        facilitator: {
          ...state.facilitator,
          ...payload,
        },
      };
    },
    setGuestName(state, { payload }: PayloadAction<string>) {
      state.guestUser.name = payload;
    },
    setGuestType(state, { payload }: PayloadAction<AddTableUserDtoTypeEnum>) {
      state.guestUser.type = payload;
    },
    setBrowserId(state, { payload }: PayloadAction<string>) {
      state.guestUser.browserId = payload;
    },
    setAuthLoading(state, { payload }: PayloadAction<boolean>) {
      state.authLoading = payload;
    },
    resetGuestUser(state) {
      state.guestUser = authInitialState.guestUser;
    },

    requestLogin(state, action: PayloadAction<LoginDto>) {},
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(profileActions.saveProfileSuccess),
      (state, { payload }) => {
        state.facilitator = { ...state.facilitator, ...payload };
      }
    );
  },
});

export const authActions = authSlice.actions;

const authReducer = authSlice.reducer;

export default authReducer;
