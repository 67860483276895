import React from 'react';
import { Container, Paper, Avatar, Typography } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import Loader from '../../components/Loader';
import { useAppDispatch } from '../../init/hooks';
import { useSelector } from 'react-redux';
import { PageTitles } from '../../global/enums';
import { Helmet } from 'react-helmet';

import { useResetPasswordStyles } from './styles';
import { Formik } from 'formik';
import { resetPasswordActions } from './slice';
import { UnauthenticatedChangePasswordDTO } from '../../generated/api-client';
import resetPasswordSelectors from './selectors';
import {
  ResetPasswordForm,
  ResetPasswordFormValues,
} from './ResetPasswordForm';
import * as Yup from 'yup';

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Not a valid email').required('Required'),
  confirmationCode: Yup.string()
    .length(8, 'Confirmation code should be 8 characters')
    .required('Required'),
  newPassword: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Required'),
  confirmNewPassword: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'New passwords must match'),
});

const formInitialValues: ResetPasswordFormValues = {
  email: '',
  confirmationCode: '',
  newPassword: '',
  confirmNewPassword: '',
};

export default function ResetPasswordPage() {
  const styles = useResetPasswordStyles();
  const dispatch = useAppDispatch();
  const loading = useSelector(resetPasswordSelectors.loading);

  const handleOnSubmit = (form: ResetPasswordFormValues) => {
    console.log(typeof form.confirmationCode);
    const payload: UnauthenticatedChangePasswordDTO = {
      email: form.email,
      confirmationCode: form.confirmationCode.toString(),
      newPassword: form.newPassword,
    };
    dispatch(resetPasswordActions.requestResetPassword(payload));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{PageTitles.ResetPassword}</title>
      </Helmet>
      <Container component="main" maxWidth="xs" className={styles.container}>
        <Paper className={styles.paper}>
          <Avatar className={styles.avatar}>
            <KeyIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          <Formik
            initialValues={formInitialValues}
            validationSchema={ResetPasswordSchema}
            onSubmit={handleOnSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {(form) => {
              return <ResetPasswordForm form={form} />;
            }}
          </Formik>
        </Paper>
      </Container>
      <Loader open={loading} />
    </React.Fragment>
  );
}
