import { createSelector } from 'reselect';
import { RootState } from '../../global/redux/store';

const selectProfile = (state: RootState) => state.profile;

const profileSelectors = {
  selectIsProfileLoading: createSelector(
    selectProfile,
    (state) => state.isLoading
  ),
  selectPlans: createSelector(selectProfile, (state) => state.plans),
  selectPlanForm: createSelector(selectProfile, (state) => state.planForm),
  selectChangePasswordModalOpen: createSelector(
    selectProfile,
    (state) => state.changePasswordModalOpen
  ),
};

export default profileSelectors;
