import { Grid } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTableStyles } from './styles';
import { startChannel } from '../../global/redux/socket/actions';
import EnterTableForm from './components/EnterTableForm';
import { useAppDispatch } from '../../init/hooks';
import { tableActions } from './slice';
import { authActions } from '../Login/slice';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import tableSelectors from './selectors';
import authSelectors from '../Login/selectors';
import { useSelector } from 'react-redux';
import SpectatorList from './components/SpectatorList';
import ParticipantsList from './components/ParticipantsList';
import FacilitatorActions from './components/FacilitatorActions';
import OptionsRow from './components/OptionsRow';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../global/enums';
import Loader from '../../components/Loader';
import useTablePlan from '../../hooks/useTablePlan';

export default function Table() {
  const { slug } = useParams();
  const isUserLoggedIn = useSelector(authSelectors.selectIsUserLoggedIn);
  const isGuestUserInTable = useSelector(
    tableSelectors.selectIsGuestUserInTable
  );
  const loading = useSelector(tableSelectors.selectLoading);
  const isTableOwner = useSelector(tableSelectors.selectIsTableOwner);
  const classes = useTableStyles();
  const isGuestUserSpectator = useSelector(
    authSelectors.selectIsGuestUserSpectator
  );
  const guestUser = useSelector(authSelectors.selectGuestUser);
  const activeUsers = useSelector(tableSelectors.selectActiveUsers);
  const dispatch = useAppDispatch();
  const { isTableFull } = useTablePlan();

  useEffect(() => {
    window.addEventListener('beforeunload', removeActiveUser);
    return () => {
      window.removeEventListener('beforeunload', removeActiveUser);
    };
  }, []);

  // When user leaves the site
  const removeActiveUser = () => {
    if (!isTableOwner) {
      dispatch(tableActions.setLeaveRequestPending(true));
      dispatch(tableActions.requestLeaveTable());
    }
  };

  // When component unmounts
  useEffect(
    () => () => {
      if (!isTableOwner) {
        dispatch(tableActions.setLeaveRequestPending(true));
        dispatch(tableActions.requestLeaveTable());
      }
    },
    []
  );

  useEffect(() => {
    if (!isTableOwner && _.isEmpty(guestUser.browserId)) {
      dispatch(authActions.setBrowserId(uuidv4()));
    } else if (!_.isEmpty(guestUser.browserId) && isTableOwner) {
      dispatch(authActions.setBrowserId(''));
    }
  }, [isTableOwner, guestUser]);

  useEffect(() => {
    if (isGuestUserInTable || isUserLoggedIn || isTableOwner) {
      dispatch(startChannel());
    }
  }, [isGuestUserInTable, isUserLoggedIn, activeUsers, isTableOwner]);

  useEffect(() => {
    if (slug) {
      dispatch(tableActions.requestGetTable(slug));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Loader open={loading} />
      <Helmet>
        <title>{PageTitles.Table}</title>
      </Helmet>
      {isTableFull && !isGuestUserInTable && !isTableOwner ? null : (
        <Grid
          container
          className={classes.container}
          justifyContent="center"
          alignContent="center"
        >
          <Grid item xs={2}>
            {isTableOwner && <FacilitatorActions />}
            {/* <Timer /> */}
            <SpectatorList />
          </Grid>
          <Grid item xs={8}>
            <ParticipantsList />
          </Grid>
          <Grid item xs={2}>
            {isGuestUserInTable && !isGuestUserSpectator && <OptionsRow />}
          </Grid>
        </Grid>
      )}
      <EnterTableForm open={!isGuestUserInTable && !isTableOwner} />
    </React.Fragment>
  );
}
