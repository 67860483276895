import React, { useState } from 'react';
import {
  Button,
  TextField,
  Grid,
  Container,
  Paper,
  Avatar,
  Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAuthStyles } from './styles';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import { PageRoutes } from '../../global/types';
import ReactGA from 'react-ga';
import { useAppDispatch } from '../../init/hooks';
import { authActions } from './slice';
import { useSelector } from 'react-redux';
import authSelectors from './selectors';
import { PageTitles } from '../../global/enums';
import { Helmet } from 'react-helmet';

export default function Auth() {
  const classes = useAuthStyles();
  const [nonRegisteredEmailError] = useState(false);
  const [wrongPassError] = useState(false);
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const loading = useSelector(authSelectors.selectAuthLoading);

  const handleChange = (
    type: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormValues({
      ...formValues,
      [type]: e.target.value,
    });
  };

  const handleSubmit = () => {
    dispatch(authActions.requestLogin(formValues));
    ReactGA.event({
      category: 'User',
      action: 'Login Attempt',
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{PageTitles.Login}</title>
      </Helmet>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form noValidate className={classes.form}>
            <TextField
              error={nonRegisteredEmailError}
              helperText={
                nonRegisteredEmailError
                  ? "We don't have that email in our system"
                  : null
              }
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formValues.email}
              onChange={(e) => handleChange('email', e)}
            />
            <TextField
              error={wrongPassError}
              helperText={wrongPassError ? 'Incorrect password' : null}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={formValues.password}
              onChange={(e) => handleChange('password', e)}
              autoComplete="current-password"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item>
                <Link to={PageRoutes.Signup} className={classes.link}>
                  Don&apos;t have an account? Sign Up
                </Link>
              </Grid>
              <Grid item>
                <Link to={PageRoutes.ForgotPassword} className={classes.link}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
      <Loader open={loading} />
    </React.Fragment>
  );
}
