/* tslint:disable */
/* eslint-disable */
/**
 * API
 * The API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetTableOwnerDetailsResponse } from '../model';
// @ts-ignore
import { UpdateUserDto } from '../model';
// @ts-ignore
import { UpdateUserPlanDto } from '../model';
// @ts-ignore
import { UpdateUserResponse } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableOwnerPlan: async (ownerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerId' is not null or undefined
            assertParamExists('getTableOwnerPlan', 'ownerId', ownerId)
            const localVarPath = `/api/user/get-table-owner-plan/{ownerId}`
                .replace(`{${"ownerId"}}`, encodeURIComponent(String(ownerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('updateUser', 'updateUserDto', updateUserDto)
            const localVarPath = `/api/user/update-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserPlanDto} updateUserPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlan: async (updateUserPlanDto: UpdateUserPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserPlanDto' is not null or undefined
            assertParamExists('updateUserPlan', 'updateUserPlanDto', updateUserPlanDto)
            const localVarPath = `/api/user/update-user-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTableOwnerPlan(ownerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTableOwnerDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTableOwnerPlan(ownerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserPlanDto} updateUserPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPlan(updateUserPlanDto: UpdateUserPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPlan(updateUserPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableOwnerPlan(ownerId: string, options?: any): AxiosPromise<GetTableOwnerDetailsResponse> {
            return localVarFp.getTableOwnerPlan(ownerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(updateUserDto: UpdateUserDto, options?: any): AxiosPromise<UpdateUserResponse> {
            return localVarFp.updateUser(updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserPlanDto} updateUserPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPlan(updateUserPlanDto: UpdateUserPlanDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.updateUserPlan(updateUserPlanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getTableOwnerPlan operation in UserApi.
 * @export
 * @interface UserApiGetTableOwnerPlanRequest
 */
export interface UserApiGetTableOwnerPlanRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiGetTableOwnerPlan
     */
    readonly ownerId: string
}

/**
 * Request parameters for updateUser operation in UserApi.
 * @export
 * @interface UserApiUpdateUserRequest
 */
export interface UserApiUpdateUserRequest {
    /**
     * 
     * @type {UpdateUserDto}
     * @memberof UserApiUpdateUser
     */
    readonly updateUserDto: UpdateUserDto
}

/**
 * Request parameters for updateUserPlan operation in UserApi.
 * @export
 * @interface UserApiUpdateUserPlanRequest
 */
export interface UserApiUpdateUserPlanRequest {
    /**
     * 
     * @type {UpdateUserPlanDto}
     * @memberof UserApiUpdateUserPlan
     */
    readonly updateUserPlanDto: UpdateUserPlanDto
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {UserApiGetTableOwnerPlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getTableOwnerPlan(requestParameters: UserApiGetTableOwnerPlanRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getTableOwnerPlan(requestParameters.ownerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(requestParameters: UserApiUpdateUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(requestParameters.updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUpdateUserPlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserPlan(requestParameters: UserApiUpdateUserPlanRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserPlan(requestParameters.updateUserPlanDto, options).then((request) => request(this.axios, this.basePath));
    }
}
