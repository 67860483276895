import React, { useState } from 'react';
import {
  Button,
  TextField,
  Container,
  Paper,
  Avatar,
  Typography,
} from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import Loader from '../../components/Loader';
import { useAppDispatch } from '../../init/hooks';
import { useSelector } from 'react-redux';
import { PageTitles } from '../../global/enums';
import { Helmet } from 'react-helmet';
import { useForgotPasswordStyles } from './styles';
import { forgotPasswordActions } from './slice';
import forgotPasswordSelectors from './selectors';

export default function ForgotPasswordPage() {
  const classes = useForgotPasswordStyles();
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState({
    email: '',
  });
  const loading = useSelector(forgotPasswordSelectors.loading);

  const handleChange = (
    type: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormValues({
      ...formValues,
      [type]: e.target.value,
    });
  };

  const handleSubmit = () => {
    dispatch(forgotPasswordActions.requestForgotPassword(formValues));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{PageTitles.ForgotPassword}</title>
      </Helmet>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <KeyIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot password
          </Typography>
          <form noValidate className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formValues.email}
              onChange={(e) => handleChange('email', e)}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </form>
        </Paper>
      </Container>
      <Loader open={loading} />
    </React.Fragment>
  );
}
