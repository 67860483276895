import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateUserDto } from '../../generated/api-client';
import { ChangePasswordDto } from '../../generated/api-client/model/change-password-dto';
import { Plan } from '../../generated/api-client/model/plan';
import { UpdateUserPlanDto } from '../../generated/api-client/model/update-user-plan-dto';

interface SetPlanPayload {
  plan?: number;
  planName?: string;
}

export interface ProfileState {
  isLoading: boolean;
  plans?: Plan[];
  planForm: {
    plan?: number;
    planName?: string;
  };
  changePasswordModalOpen: boolean;
}

const profileInitialState: ProfileState = {
  isLoading: false,
  planForm: {},
  changePasswordModalOpen: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: profileInitialState,
  reducers: {
    saveProfileSuccess(state, { payload }: PayloadAction<UpdateUserDto>) {
      state.isLoading = false;
    },
    saveProfileFailure(state) {
      state.isLoading = false;
    },
    getAllPlansSuccess(state, { payload }: PayloadAction<Plan[]>) {
      state.isLoading = false;
      state.plans = payload;
    },
    getAllPlansFailure(state) {
      state.isLoading = false;
    },
    updateUserPlanSuccess(state) {
      state.isLoading = false;
    },
    updateUserPlanFailure(state) {
      state.isLoading = false;
    },
    changePasswordSuccess(state) {
      state.isLoading = false;
      state.changePasswordModalOpen = false;
    },
    changePasswordFailure(state) {
      state.isLoading = false;
    },
    setPlan(state, { payload }: PayloadAction<SetPlanPayload>) {
      state.planForm.plan = payload.plan;
      state.planForm.planName = payload.planName;
    },
    setChangePasswordModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.changePasswordModalOpen = payload;
    },

    requestSaveProfile(state, { payload }: PayloadAction<UpdateUserDto>) {
      state.isLoading = true;
    },
    requestGetAllPlans(state) {
      state.isLoading = true;
    },
    requestUpdateUserPlan(
      state,
      { payload }: PayloadAction<UpdateUserPlanDto>
    ) {
      state.isLoading = true;
    },
    requestChangePassword(
      state,
      { payload }: PayloadAction<ChangePasswordDto>
    ) {
      state.isLoading = true;
    },
  },
  // extraReducers: (builder) => {
  //   // TODO BG - need to figure out why this isn't working
  //   builder.addMatcher(isAnyOf(authActions.logout), () => {
  //     return profileInitialState
  //   })
  // },
});

export const profileActions = profileSlice.actions;

const profileReducer = profileSlice.reducer;

export default profileReducer;
