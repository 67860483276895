import React, { useEffect, useState } from 'react';
import MainTable from '../../../../components/Tables/MainTable';
import {
  CellData,
  RowData,
  TableData,
} from '../../../../components/Tables/MainTable/types';
import { IconButton, Typography } from '@mui/material';
import { useAppDispatch } from '../../../../init/hooks';
import { v4 } from 'uuid';
import { Edit } from '@mui/icons-material';
import { homeActions } from '../../slice';
import { VotingSystem } from '../../../../generated/api-client';
import { useSelector } from 'react-redux';
import homeSelectors from '../../selectors';
import authSelectors from '../../../Login/selectors';
import useUserPlan from '../../../../hooks/useUserPlan';

export default function VotingSystemsWidget() {
  const dispatch = useAppDispatch();
  const [tableData, setTableData] = useState<TableData | null>(null);
  const votingSystems = useSelector(homeSelectors.selectVotingSystems);
  const votingSystemsLoading = useSelector(
    homeSelectors.selectVotingSystemsLoading
  );
  const userId = useSelector(authSelectors.selectFacilitatorId);
  const { hasCustomPointingScales } = useUserPlan();

  useEffect(() => {
    dispatch(homeActions.requestVotingSystems());
  }, []);

  useEffect(() => {
    if (votingSystems) {
      const temp: TableData = {
        rows: votingSystemToRowArray(),
      };
      setTableData(temp);
    }
  }, [votingSystems]);

  const handleEditClick = ({ _id, name, options }: VotingSystem) => {
    dispatch(
      homeActions.setVotingSystemModalEdit({
        open: true,
        id: _id,
        name,
        options,
      })
    );
  };

  const votingSystemToRowArray = () => {
    const rows: RowData[] = [];
    if (hasCustomPointingScales) {
      votingSystems.owned.forEach((votingSystem: VotingSystem) => {
        rows.push({
          cells: votingSystemToCellArray(votingSystem),
          _id: votingSystem._id,
        });
      });
    }

    votingSystems.adminOwned.forEach((votingSystem: VotingSystem) => {
      rows.push({
        cells: votingSystemToCellArray(votingSystem),
        _id: votingSystem._id,
      });
    });
    return rows;
  };

  const votingSystemToCellArray = (votingSystem: VotingSystem) => {
    const cells: CellData[] = [];
    cells.push({
      _id: v4(),
      component: <>{votingSystem.name}</>,
    });

    cells.push({
      _id: v4(),
      component: <Typography>{votingSystem.options.join(', ')}</Typography>,
    });

    if (userId === votingSystem.owner && hasCustomPointingScales) {
      cells.push({
        _id: v4(),
        component: (
          <IconButton
            onClick={() => handleEditClick(votingSystem)}
            size="large"
          >
            <Edit fontSize="small" />
          </IconButton>
        ),
      });
    }

    return cells;
  };

  const headerData = {
    headerCells: [{ name: 'Voting System' }, { name: 'Options' }, { name: '' }],
  };

  return (
    <MainTable
      noRowsMessage="You don't have any voting systems yet"
      loading={votingSystemsLoading}
      tableHeaderData={headerData}
      tableData={tableData}
    />
  );
}
