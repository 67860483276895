import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import VotingSystemSelect from './VotingSystemSelect';
import { Grid, useTheme } from '@mui/material';
import { useAppDispatch } from '../../../../init/hooks';
import { homeActions } from '../../slice';
import { useSelector } from 'react-redux';
import homeSelectors from '../../selectors';
import authSelectors from '../../../Login/selectors';

export default function CreateTableModal() {
  const isTableFormSubmitDisabled = useSelector(
    homeSelectors.selectIsTableFormSubmitDisabled
  );
  const tableForm = useSelector(homeSelectors.selectTableForm);
  const facilitator = useSelector(authSelectors.selectFacilitator);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const handleClose = () => {
    dispatch(homeActions.resetTableFormValues());
  };

  const handleDelete = () => {
    if (tableForm.id) {
      dispatch(homeActions.requestDeleteTable(tableForm.id));
    }
  };

  const handleSubmit = () => {
    if (tableForm.selectedVotingSystem) {
      if (tableForm.id) {
        dispatch(
          homeActions.requestUpdateTable({
            _id: tableForm.id,
            tableName: tableForm.name,
            votingSystem: tableForm.selectedVotingSystem,
            owner: facilitator._id,
          })
        );
      } else {
        dispatch(
          homeActions.requestCreateTable({
            tableName: tableForm.name,
            votingSystem: tableForm.selectedVotingSystem,
            owner: facilitator._id,
            plan: facilitator.plan,
          })
        );
      }
    }
  };

  return (
    <Dialog
      open={tableForm.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {tableForm.id ? 'Edit Table' : 'Create New Table'}
      </DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Grid container direction="column">
          <Grid item>
            <TextField
              autoFocus
              value={tableForm.name}
              onChange={(e) =>
                dispatch(homeActions.setTableFormName(e.currentTarget.value))
              }
              label="Table Name"
              variant="outlined"
              fullWidth
              sx={{ marginTop: theme.spacing(1) }}
            />
          </Grid>
          <Grid item>
            <VotingSystemSelect />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            {tableForm.id && (
              <Button onClick={handleDelete} color="secondary">
                Delete
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={isTableFormSubmitDisabled}
            >
              {tableForm.id ? 'Edit' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
