import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../init/hooks';
import clsx from 'clsx';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/styles';
import { profileActions } from '../slice';
import { useSelector } from 'react-redux';
import authSelectors from '../../Login/selectors';
import { useFacilitatorInformationSectionStyles } from './styles';
import { ChangePasswordModal } from './ChangePasswordModal';

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First name is required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

export default function FacilitatorInformationSection() {
  const classes = useFacilitatorInformationSectionStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const userId = useSelector(authSelectors.selectFacilitatorId);
  const facilitator = useSelector(authSelectors.selectFacilitator);

  const formInitialValues: FormValues = {
    firstName: facilitator.firstName || '',
    lastName: facilitator.lastName || '',
    email: facilitator.email,
  };

  const handleOnSubmit = (values: FormValues) => {
    dispatch(
      profileActions.requestSaveProfile({
        _id: userId,
        ...values,
      })
    );
  };

  const iconText = React.useMemo(() => {
    if (facilitator.firstName && facilitator.lastName) {
      return `${facilitator.firstName[0].toUpperCase()}${facilitator.lastName[0].toUpperCase()}`;
    }
    return '';
  }, [facilitator]);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={ProfileSchema}
      onSubmit={handleOnSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={classes.container}>
              <CardContent>
                <Grid container style={{ marginLeft: theme.spacing(2) }}>
                  <Grid item xs={6}>
                    <Typography variant="h5">
                      Facilitator Information
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container justifyContent="end">
                      <Button
                        style={{ marginRight: theme.spacing(4) }}
                        onClick={() => handleSubmit()}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item m={1}>
                    <div className={classes.avatar}>
                      <div className={classes.avatarDefault}>
                        <Typography>{iconText}</Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Grid item xs={6}>
                        <TextField
                          className={classes.textField}
                          placeholder="First name"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          error={!!errors.firstName}
                          helperText={errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          className={classes.textField}
                          placeholder="Last name"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          fullWidth
                          error={!!errors.lastName}
                          helperText={errors.lastName || ''}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Grid item xs={6}>
                        <TextField
                          className={clsx(classes.textField, classes.marginTop)}
                          placeholder="Email"
                          name="email"
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          fullWidth
                          error={!!errors.email}
                          helperText={errors.email}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={3} style={{ textAlign: 'end' }}>
                    <ChangePasswordModal />
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
}
