import React from "react";
import Snackbar from "@mui/material/Snackbar";
import _ from "lodash";
import Alert from "@mui/material/Alert";
import { useAppDispatch } from "../../init/hooks";
import { useSelector } from "react-redux";
import notificationSelectors from "./selectors";
import { notificationActions } from "./slice";

export default function Notification() {
  const notification = useSelector(notificationSelectors.selectNotification);
  const dispatch = useAppDispatch();

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(notificationActions.resetNotification());
  };

  return (
    <div>
      {notification.message && (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={!_.isEmpty(notification.message)}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={notification.variant}
            elevation={6}
            variant="filled"
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
