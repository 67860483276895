import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ForgotPasswordDto } from '../../generated/api-client/model/forgot-password-dto';

export interface ForgotPasswordState {
  loading: boolean;
}

const forgotPasswordInitialState: ForgotPasswordState = {
  loading: false,
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: forgotPasswordInitialState,
  reducers: {
    requestForgotPassword(state, action: PayloadAction<ForgotPasswordDto>) {
      state.loading = true;
    },
    requestForgotPasswordSuccess(state) {
      state.loading = false;
    },
    requestForgotPasswordFailure(state) {
      state.loading = false;
    },
  },
});

export const forgotPasswordActions = forgotPasswordSlice.actions;

const forgotPasswordReducer = forgotPasswordSlice.reducer;

export default forgotPasswordReducer;
