import {
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../init/hooks";
import tableSelectors from "../../selectors";
import { tableActions } from "../../slice";

export default function OptionsRow() {
  const votingSystem = useSelector(tableSelectors.selectVotingSystemDetails);
  const currentUserVote = useSelector(tableSelectors.selectCurrentUserVote);
  const votesShown = useSelector(tableSelectors.selectVotesShown);

  const dispatch = useAppDispatch();
  const handleClick = (option: string) => {
    if (option === currentUserVote) {
      dispatch(tableActions.requestUpdateUserVote(null));
    } else {
      dispatch(tableActions.requestUpdateUserVote(option));
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <List
        subheader={
          <ListSubheader id="nested-list-subheader">
            Pick your vote
          </ListSubheader>
        }
      >
        {!votesShown ? (
          <>
            {votingSystem?.options.map((option) => (
              <ListItemButton
                selected={option === currentUserVote}
                onClick={() => handleClick(option)}
              >
                <ListItemText primary={option} />
              </ListItemButton>
            ))}
          </>
        ) : (
          <Typography>Votes cannot be cast when they are shown</Typography>
        )}
      </List>
    </Box>
  );
}
