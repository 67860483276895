import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReactGA from 'react-ga';
import { useAppDispatch } from '../../../../init/hooks';
import { authActions } from '../../../Login/slice';
import { tableActions } from '../../slice';
import { AddTableUserDtoTypeEnum } from '../../../../generated/api-client';
import { useSelector } from 'react-redux';
import tableSelectors from '../../selectors';
import useTablePlan from '../../../../hooks/useTablePlan';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    minWidth: 300,
  },
}));

interface EnterTableFormProps {
  open: boolean;
}

export default function EnterTableForm({ open }: EnterTableFormProps) {
  const guestName = useSelector(tableSelectors.selectEnterTableFormName);
  const [userType, setUserType] = useState(AddTableUserDtoTypeEnum.Participant);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { isTableFull, playerLimit } = useTablePlan();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (isValid()) {
        dispatch(authActions.setGuestName(guestName));
        dispatch(
          tableActions.requestAddTableUser({
            name: guestName,
            type: userType,
          })
        );
      }
    }
  };

  const handleSubmit = () => {
    if (isValid()) {
      dispatch(authActions.setGuestName(guestName));
      dispatch(authActions.setGuestType(userType));
      dispatch(
        tableActions.requestAddTableUser({
          name: guestName,
          type: userType,
        })
      );
      ReactGA.event({
        category: 'User',
        action: 'Guest User Added',
      });
    }
  };

  const isValid = () => {
    return guestName.length !== 0;
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === AddTableUserDtoTypeEnum.Participant) {
      setUserType(AddTableUserDtoTypeEnum.Participant);
    } else if (e.target.value === AddTableUserDtoTypeEnum.Spectator) {
      setUserType(AddTableUserDtoTypeEnum.Spectator);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.dialogTitle}>Enter Table</DialogTitle>
      <DialogContent>
        {isTableFull ? (
          <DialogContentText>
            The table owner's plan only allows for {playerLimit} users at a
            time. Either upgrade the plan for wait for another user to leave
          </DialogContentText>
        ) : (
          <>
            <FormLabel component="legend">Join as:</FormLabel>
            <RadioGroup
              name="radio-buttons-group"
              value={userType}
              onChange={handleTypeChange}
            >
              <FormControlLabel
                value={AddTableUserDtoTypeEnum.Participant}
                control={<Radio />}
                label={AddTableUserDtoTypeEnum.Participant}
              />
              <FormControlLabel
                value={AddTableUserDtoTypeEnum.Spectator}
                control={<Radio />}
                label={AddTableUserDtoTypeEnum.Spectator}
              />
            </RadioGroup>
            <Divider className={classes.divider} />
            <DialogContentText>
              Type your name to enter the table
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              variant="outlined"
              fullWidth
              autoComplete="name"
              value={guestName}
              onChange={(e) => {
                dispatch(tableActions.setGuestName(e.target.value));
              }}
              onKeyDown={handleKeyDown}
            />
          </>
        )}
      </DialogContent>
      {!isTableFull && (
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={!isValid() || isTableFull}
          >
            Enter
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
