import { createSelector } from "reselect";
import { RootState } from "../../global/redux/store";

const selectSignup = (state: RootState) => state.signup;

const signupSelectors = {
  selectLoading: createSelector(selectSignup, (state) => state.loading),
};

export default signupSelectors;
