import React from 'react';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../global/enums';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import profileSelectors from './selectors';
import FacilitatorInformationSection from './FacilitatorInformationSection';

export default function Profile() {
  const isLoading = useSelector(profileSelectors.selectIsProfileLoading);

  return (
    <React.Fragment>
      <Loader open={isLoading} />
      <Helmet>
        <title>{PageTitles.Profile}</title>
      </Helmet>
      <FacilitatorInformationSection />
      {/* <PlanLevelSection /> */}
    </React.Fragment>
  );
}
