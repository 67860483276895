import ReactGA from "react-ga";
import { History } from "history";

interface RouteChangeTrackerHOCProps {
  children: JSX.Element;
  history: History;
}

const RouteChangeTrackerHOC = ({
  children,
  history,
}: RouteChangeTrackerHOCProps) => {
  history.listen((history) => {
    ReactGA.set({ page: history.location.pathname });
    ReactGA.pageview(history.location.pathname);
  });
  return children;
};

export default RouteChangeTrackerHOC;
