import { createSelector } from 'reselect';
import { ActiveUserTypeEnum } from '../../generated/api-client';
import { RootState } from '../../global/redux/store';

const selectRoot = (state: RootState) => state;
const selectTable = (state: RootState) => state.table;
const selectTableData = (state: RootState) => state.table.tableData;

const tableSelectors = {
  selectIsTableOwner: createSelector(
    selectRoot,
    (state) => state.auth.facilitator._id === state.table.tableData.owner
  ),
  selectActiveUsers: createSelector(
    selectTableData,
    (state) => state.activeUsers
  ),
  selectActiveUserCount: createSelector(
    selectTableData,
    (state) => state.activeUsers.length
  ),
  selectSpectators: createSelector(selectTableData, (state) =>
    state.activeUsers.filter(
      (user) => user.type === ActiveUserTypeEnum.Spectator
    )
  ),
  selectTableParticipants: createSelector(selectTableData, (state) =>
    state.activeUsers.filter(
      (user) => user.type === ActiveUserTypeEnum.Participant
    )
  ),
  selectVotesShown: createSelector(
    selectTableData,
    (state) => state.votesShown
  ),
  selectIsGuestUserInTable: createSelector(
    selectRoot,
    (state) =>
      state.table.tableData.activeUsers.filter(
        (user) => user.browserId === state.auth.guestUser.browserId
      ).length === 1
  ),
  selectResetTableFormOpen: createSelector(
    selectTable,
    (state) => state.resetTableForm.open
  ),
  selectTableId: createSelector(selectTable, (state) => state.tableData._id),
  selectVotingSystemDetails: createSelector(
    selectTable,
    (state) => state.votingSystemDetails
  ),
  selectTableData: createSelector(selectTable, (state) => state.tableData),
  selectEnterTableFormName: createSelector(
    selectTable,
    (state) => state.enterTableForm.name
  ),
  selectVoteCount: createSelector(
    selectTableData,
    (state) => state.activeUsers.filter((user) => user.vote !== null).length
  ),
  selectCurrentUserVote: createSelector(
    selectRoot,
    (state) =>
      state.table.tableData.activeUsers.filter(
        (user) => user.browserId === state.auth.guestUser.browserId
      )[0].vote
  ),
  selectTimer: createSelector(selectRoot, (state) => state.table.timer),
  selectLoading: createSelector(
    selectTable,
    (state) =>
      Object.values(state.loading).filter((loading) => loading).length > 0
  ),
  selectTableOwnerPlan: createSelector(
    selectTable,
    (state) => state.tableOwnerDetails.plan
  ),
};

export default tableSelectors;
