import authSagaWatcher from '../../pages/Login/sagas';
import homeSagaWatcher from '../../pages/Home/sagas';
import signupSagaWatcher from '../../pages/Signup/sagas';
import tableSagaWatcher from '../../pages/Table/sagas';
import socketSagas from './socket/sagas';
import profileSagaWatcher from '../../pages/Profile/sagas';
import forgotPasswordSagaWatcher from '../../pages/ForgotPassword/sagas';
import resetPasswordSagaWatcher from '../../pages/ResetPassword/sagas';

const sagas = [
  authSagaWatcher,
  homeSagaWatcher,
  tableSagaWatcher,
  socketSagas,
  signupSagaWatcher,
  profileSagaWatcher,
  forgotPasswordSagaWatcher,
  resetPasswordSagaWatcher,
];

export default sagas;

export function map() {
  throw new Error('Function not implemented.');
}
