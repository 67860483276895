import React, { useState } from 'react';
import {
  Button,
  TextField,
  Grid,
  Container,
  Paper,
  Avatar,
  Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useStylesSignupPage } from './styles';
import { useAppDispatch } from '../../init/hooks';
import Loader from '../../components/Loader';
import { PageRoutes } from '../../global/types';
import { signupActions } from './slice';
import { useSelector } from 'react-redux';
import signupSelectors from './selectors';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../global/enums';
import { Link } from 'react-router-dom';

export default function SignupPage() {
  const classes = useStylesSignupPage();
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  });
  const loading = useSelector(signupSelectors.selectLoading);

  const handleChange = (
    type: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormValues((prev) => ({
      ...prev,
      [type]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    dispatch(signupActions.requestSignup(formValues));
  };

  const submitDisabled = React.useMemo(() => {
    return (
      Object.values(formValues).filter((val) => val.length === 0).length > 0
    );
  }, [formValues]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{PageTitles.Signup}</title>
      </Helmet>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form noValidate className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formValues.email}
              onChange={(e) => handleChange('email', e)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First name"
              name="firstName"
              autoComplete="given-name"
              value={formValues.firstName}
              onChange={(e) => handleChange('firstName', e)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last name"
              name="lastName"
              autoComplete="family-name"
              value={formValues.lastName}
              onChange={(e) => handleChange('lastName', e)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={formValues.password}
              onChange={(e) => handleChange('password', e)}
              autoComplete="current-password"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={submitDisabled}
              onClick={handleSubmit}
            >
              Sign Up
            </Button>
            <Grid container>
              <Grid item>
                <Link to={PageRoutes.Auth} className={classes.link}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
      <Loader open={loading} />
    </React.Fragment>
  );
}
