import { createSelector } from 'reselect';
import { RootState } from '../../global/redux/store';

const selectForgotPassword = (state: RootState) => state.forgotPassword;

const forgotPasswordSelectors = {
  loading: createSelector(selectForgotPassword, (state) => state.loading),
};

export default forgotPasswordSelectors;
