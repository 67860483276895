import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

interface Props {
  Component: React.ElementType;
}

const RestrictedRoute: React.FC<Props> = ({ Component }: Props) => {
  const { isAuthenticated } = useAuth();

  return !isAuthenticated ? <Component /> : <Navigate to="/" replace />;
};

export default RestrictedRoute;
