/* tslint:disable */
/* eslint-disable */
/**
 * API
 * The API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateVotingSystemDto } from '../model';
// @ts-ignore
import { GetOwnedVotingSystemsResponse } from '../model';
// @ts-ignore
import { UpdateVotingSystemDto } from '../model';
/**
 * VotingSystemApi - axios parameter creator
 * @export
 */
export const VotingSystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/voting-system/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateVotingSystemDto} createVotingSystemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createVotingSystemDto: CreateVotingSystemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVotingSystemDto' is not null or undefined
            assertParamExists('create', 'createVotingSystemDto', createVotingSystemDto)
            const localVarPath = `/api/voting-system/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVotingSystemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOne', 'id', id)
            const localVarPath = `/api/voting-system/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwned: async (ownerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerId' is not null or undefined
            assertParamExists('getOwned', 'ownerId', ownerId)
            const localVarPath = `/api/voting-system/owned/{ownerId}`
                .replace(`{${"ownerId"}}`, encodeURIComponent(String(ownerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateVotingSystemDto} updateVotingSystemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (updateVotingSystemDto: UpdateVotingSystemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateVotingSystemDto' is not null or undefined
            assertParamExists('update', 'updateVotingSystemDto', updateVotingSystemDto)
            const localVarPath = `/api/voting-system`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVotingSystemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VotingSystemApi - functional programming interface
 * @export
 */
export const VotingSystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VotingSystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateVotingSystemDto} createVotingSystemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createVotingSystemDto: CreateVotingSystemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createVotingSystemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwned(ownerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOwnedVotingSystemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwned(ownerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateVotingSystemDto} updateVotingSystemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(updateVotingSystemDto: UpdateVotingSystemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(updateVotingSystemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VotingSystemApi - factory interface
 * @export
 */
export const VotingSystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VotingSystemApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateVotingSystemDto} createVotingSystemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createVotingSystemDto: CreateVotingSystemDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.create(createVotingSystemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.getOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwned(ownerId: string, options?: any): AxiosPromise<GetOwnedVotingSystemsResponse> {
            return localVarFp.getOwned(ownerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateVotingSystemDto} updateVotingSystemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(updateVotingSystemDto: UpdateVotingSystemDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.update(updateVotingSystemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in VotingSystemApi.
 * @export
 * @interface VotingSystemApiDeleteRequest
 */
export interface VotingSystemApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof VotingSystemApiDelete
     */
    readonly id: string
}

/**
 * Request parameters for create operation in VotingSystemApi.
 * @export
 * @interface VotingSystemApiCreateRequest
 */
export interface VotingSystemApiCreateRequest {
    /**
     * 
     * @type {CreateVotingSystemDto}
     * @memberof VotingSystemApiCreate
     */
    readonly createVotingSystemDto: CreateVotingSystemDto
}

/**
 * Request parameters for getOne operation in VotingSystemApi.
 * @export
 * @interface VotingSystemApiGetOneRequest
 */
export interface VotingSystemApiGetOneRequest {
    /**
     * 
     * @type {string}
     * @memberof VotingSystemApiGetOne
     */
    readonly id: string
}

/**
 * Request parameters for getOwned operation in VotingSystemApi.
 * @export
 * @interface VotingSystemApiGetOwnedRequest
 */
export interface VotingSystemApiGetOwnedRequest {
    /**
     * 
     * @type {string}
     * @memberof VotingSystemApiGetOwned
     */
    readonly ownerId: string
}

/**
 * Request parameters for update operation in VotingSystemApi.
 * @export
 * @interface VotingSystemApiUpdateRequest
 */
export interface VotingSystemApiUpdateRequest {
    /**
     * 
     * @type {UpdateVotingSystemDto}
     * @memberof VotingSystemApiUpdate
     */
    readonly updateVotingSystemDto: UpdateVotingSystemDto
}

/**
 * VotingSystemApi - object-oriented interface
 * @export
 * @class VotingSystemApi
 * @extends {BaseAPI}
 */
export class VotingSystemApi extends BaseAPI {
    /**
     * 
     * @param {VotingSystemApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotingSystemApi
     */
    public _delete(requestParameters: VotingSystemApiDeleteRequest, options?: AxiosRequestConfig) {
        return VotingSystemApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VotingSystemApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotingSystemApi
     */
    public create(requestParameters: VotingSystemApiCreateRequest, options?: AxiosRequestConfig) {
        return VotingSystemApiFp(this.configuration).create(requestParameters.createVotingSystemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VotingSystemApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotingSystemApi
     */
    public getOne(requestParameters: VotingSystemApiGetOneRequest, options?: AxiosRequestConfig) {
        return VotingSystemApiFp(this.configuration).getOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VotingSystemApiGetOwnedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotingSystemApi
     */
    public getOwned(requestParameters: VotingSystemApiGetOwnedRequest, options?: AxiosRequestConfig) {
        return VotingSystemApiFp(this.configuration).getOwned(requestParameters.ownerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VotingSystemApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotingSystemApi
     */
    public update(requestParameters: VotingSystemApiUpdateRequest, options?: AxiosRequestConfig) {
        return VotingSystemApiFp(this.configuration).update(requestParameters.updateVotingSystemDto, options).then((request) => request(this.axios, this.basePath));
    }
}
