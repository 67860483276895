import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { useFacilitatorInformationSectionStyles } from './styles';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '../slice';
import authSelectors from '../../Login/selectors';
import profileSelectors from '../selectors';

const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Required'),
  confirmNewPassword: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'New passwords must match'),
});

interface FormValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const formInitialValues: FormValues = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

export const ChangePasswordModal = () => {
  const styles = useFacilitatorInformationSectionStyles();
  const userId = useSelector(authSelectors.selectFacilitatorId);
  const modalOpen = useSelector(profileSelectors.selectChangePasswordModalOpen);
  const dispatch = useDispatch();

  const handleOnSubmit = (form: FormValues) => {
    dispatch(
      profileActions.requestChangePassword({
        _id: userId,
        currentPassword: form.currentPassword,
        newPassword: form.newPassword,
      })
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() =>
          dispatch(profileActions.setChangePasswordModalOpen(true))
        }
      >
        Change your password
      </Button>
      <Dialog open={modalOpen}>
        <DialogTitle>Change your password</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={formInitialValues}
            validationSchema={ChangePasswordSchema}
            onSubmit={handleOnSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    value={values.currentPassword}
                    placeholder="Current password"
                    name="currentPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    className={styles.modalInput}
                    error={!!errors.currentPassword}
                    helperText={errors.currentPassword || ''}
                    type="password"
                  />
                  <TextField
                    value={values.newPassword}
                    placeholder="New password"
                    name="newPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    className={styles.modalInput}
                    error={!!errors.newPassword}
                    helperText={errors.newPassword || ''}
                    type="password"
                  />
                  <TextField
                    value={values.confirmNewPassword}
                    placeholder="Confirm new password"
                    name="confirmNewPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    className={styles.modalInput}
                    error={!!errors.confirmNewPassword}
                    helperText={errors.confirmNewPassword || ''}
                    type="password"
                  />
                  <DialogActions>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() =>
                        dispatch(
                          profileActions.setChangePasswordModalOpen(false)
                        )
                      }
                    >
                      cancel
                    </Button>
                    <Button variant="contained" onClick={() => handleSubmit()}>
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};
