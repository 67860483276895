import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { signupActions } from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { SignupResponse } from '../../generated/api-client';
import { ApiClient } from '../../global/apiClient';
import { SignupDto } from '../../generated/api-client/model/signup-dto';
import { authActions } from '../Login/slice';
import {
  notificationActions,
  NotificationVariantEnum,
} from '../../components/Notification/slice';

export function* requestSignup({ payload }: PayloadAction<SignupDto>) {
  try {
    yield put(signupActions.setLoading(true));
    const res: AxiosResponse<SignupResponse> = yield call(
      ApiClient.auth.signup,
      payload
    );

    const { data } = res;

    yield put(
      authActions.setAuth({
        token: data.token,
        ...data.user,
      })
    );
    yield put(authActions.resetGuestUser());
  } catch (e) {
    yield put(
      notificationActions.setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error with the signup request',
      })
    );
  } finally {
    yield put(signupActions.setLoading(false));
  }
}

export function* signupSagaWatcher() {
  yield takeLatest(signupActions.requestSignup, requestSignup);
}

export default signupSagaWatcher;
