import { createSelector } from "reselect";
import { RootState } from "./global/redux/store";

const selectApp = (state: RootState) => state.app;

const appSelectors = {
  selectThemeMode: createSelector(selectApp, (state) => state.themeMode),
};

export default appSelectors;
