import React from 'react';
import { useSelector } from 'react-redux';
import authSelectors from '../pages/Login/selectors';
import { getPlanDetails } from './useTablePlan';

export default function useUserPlan() {
  const user = useSelector(authSelectors.selectFacilitator);

  const hasCustomPointingScales = React.useMemo(
    () => getPlanDetails(user.plan)?.customPointingScales,
    [user.plan]
  );

  return {
    hasCustomPointingScales,
  };
}
