import { Button, Grid, useTheme } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import tableSelectors from "../../selectors";
import { tableActions } from "../../slice";

export default function FacilitatorActions() {
  const voteCount = useSelector(tableSelectors.selectVoteCount);
  const votesShown = useSelector(tableSelectors.selectVotesShown);
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleShowVotes = () => {
    dispatch(tableActions.requestFlipCards());
  };

  const handleStartNewVote = () => {
    dispatch(tableActions.requestStartNewVote());
  };

  return (
    <Grid container flexDirection="column">
      <Grid item sx={{ marginTop: theme.spacing(1) }}>
        <Button
          variant="contained"
          disabled={voteCount === 0 || votesShown}
          onClick={handleShowVotes}
        >
          Show Votes
        </Button>
      </Grid>
      <Grid item sx={{ marginTop: theme.spacing(1) }}>
        <Button
          variant="contained"
          disabled={voteCount === 0}
          onClick={handleStartNewVote}
        >
          Start New Vote
        </Button>
      </Grid>
    </Grid>
  );
}
