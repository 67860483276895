import { createSelector } from "reselect";
import { RootState } from "../../global/redux/store";

const selectRoot = (state: RootState) => state;

const notificationSelectors = {
  selectNotification: createSelector(selectRoot, (state) => state.notification),
};

export default notificationSelectors;
