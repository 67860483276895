import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authActions } from '../../pages/Login/slice';

export enum NotificationVariantEnum {
  Success = 'success',
  Error = 'error',
}

export interface NotificationState {
  message: string;
  variant: NotificationVariantEnum;
}

const notificationIntialState: NotificationState = {
  message: '',
  variant: NotificationVariantEnum.Success,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState: notificationIntialState,
  reducers: {
    setNotification(state, { payload }: PayloadAction<NotificationState>) {
      return payload;
    },
    resetNotification(state) {
      return notificationIntialState;
    },
  },
  extraReducers: {
    [authActions.logout.type]: () => {
      return notificationIntialState;
    },
  },
});

export const notificationActions = notificationSlice.actions;

const notificationReducer = notificationSlice.reducer;

export default notificationReducer;
