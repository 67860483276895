import { Card, CardContent, Grid, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import tableSelectors from "../../selectors";
import authSelectors from "../../../Login/selectors";
import _ from "lodash";
import { SxProps } from "@mui/system";
import appSelectors from "../../../../selectors";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useAppDispatch } from "../../../../init/hooks";
import { tableActions } from "../../slice";

export default function ParticipantsList() {
  const participants = useSelector(tableSelectors.selectTableParticipants);
  const votesShown = useSelector(tableSelectors.selectVotesShown);
  const browserId = useSelector(authSelectors.selectGuestUserBrowserId);
  const themeMode = useSelector(appSelectors.selectThemeMode);
  const isTableOwner = useSelector(tableSelectors.selectIsTableOwner);
  const tableId = useSelector(tableSelectors.selectTableId);
  const theme = useTheme();
  const dispatch = useAppDispatch()


  const activeStyles: SxProps = {
    backgroundColor: theme.palette.primary.dark,
    color:
      themeMode === "dark"
        ? theme.palette.common.black
        : theme.palette.common.white,
    width: 100,
  };

  const inactiveStyles: SxProps = {
    backgroundColor: theme.palette.background.paper,
    color:
      themeMode === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
    width: 100,
  };

  const handleRemoveUser = (browserId: string) => {
    dispatch(tableActions.requestRemoveUser({ tableId, browserId }))
  }

  return (
    <>
      <Grid container>
        {participants.map((user) => {
          const currentStyles = !_.isEmpty(user.vote) ? activeStyles : inactiveStyles;
          return (
            <Grid item m={1} key={user.browserId}>
              <Card sx={currentStyles} key={user.browserId}>
                <CardContent
                  sx={{ padding: isTableOwner ? 0 : undefined, paddingBottom: `${theme.spacing(1)} !important` }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    flexDirection="column"
                    sx={{ height: 100 }}
                  >
                    {isTableOwner && (
                    <Grid item alignSelf='end'>
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveUser(user.browserId)}
                        color="inherit"
                      >
                        <RemoveCircleOutlineIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                    )}
                    <Grid item>
                      <Typography
                        gutterBottom
                        variant="body1"
                        textAlign="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        {votesShown || user.browserId === browserId
                          ? user.vote
                          : undefined}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        gutterBottom
                        variant="body1"
                        sx={{ overflowWrap: "anywhere" }}
                        textAlign="center"
                        fontWeight={
                          user.browserId === browserId ? "bold" : undefined
                        }
                      >
                        {_.startCase(user.name)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
