import React, { ReactNode } from "react";
import Header from "../Header";
import { Container } from "@mui/material";
import { useStyles_LayoutWrapper } from "./styles";
import Notification from "../../Notification";

export interface ILayoutWrapperProps {
  children?: ReactNode;
}

export default function LayoutWrapper({ children }: ILayoutWrapperProps) {
  const classes = useStyles_LayoutWrapper();
  return (
    <div>
      <Header />
      <Notification />
      <Container className={classes.container}>
        <>{children}</>
      </Container>
    </div>
  );
}
