import React from 'react';
import { useSelector } from 'react-redux';
import { Plans } from '../global/enums';
import tableSelectors from '../pages/Table/selectors';

interface PlanMap {
  [plan: number]: {
    playerLimit: number;
    tableLimit: number;
    price: number;
    planName: string;
    plan: number;
    customPointingScales: boolean;
  };
}

export const planMap: PlanMap = {
  [Plans.Free]: {
    playerLimit: 3,
    plan: Plans.Free,
    tableLimit: 1,
    price: 0,
    planName: 'Free',
    customPointingScales: false,
  },
  [Plans.Basic]: {
    playerLimit: 10,
    tableLimit: 3,
    price: 499,
    plan: Plans.Basic,
    planName: 'Basic',
    customPointingScales: true,
  },
  [Plans.Plus]: {
    playerLimit: 30,
    tableLimit: 10,
    price: 1099,
    plan: Plans.Plus,
    planName: 'Plus',
    customPointingScales: true,
  },
};

export const getPlanDetails = (plan: Plans | undefined) => {
  if (plan) {
    return planMap[plan];
  } else {
    return undefined;
  }
};

export default function useTablePlan() {
  const tablePlan = useSelector(tableSelectors.selectTableOwnerPlan);
  const activeUserCount = useSelector(tableSelectors.selectActiveUserCount);

  const isTableFull = React.useMemo(() => {
    const playerLimit = getPlanDetails(tablePlan)?.playerLimit;
    if (!playerLimit) {
      return true;
    }
    if (playerLimit && playerLimit <= activeUserCount) {
      return true;
    }

    return false;
  }, [getPlanDetails, tablePlan, activeUserCount]);

  return {
    planDetails: getPlanDetails(tablePlan),
    isTableFull,
    playerLimit: getPlanDetails(tablePlan)?.playerLimit,
  };
}
