import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateTableDto,
  Table,
  UpdateTableDto,
  UpdateVotingSystemDto,
  VotingSystem,
} from '../../generated/api-client';
import { GetOwnedVotingSystemsResponse } from '../../generated/api-client/model/get-owned-voting-systems-response';
import { authActions } from '../Login/slice';
import {
  SetVotingSystemModalOpenPayload,
  SetVotingSystemOption,
} from './types';

interface TableForm {
  open: boolean;
  selectedVotingSystem: string | null;
  loading: boolean;
  name: string;
  id: string | null;
}

interface VotingSystemModal {
  open: boolean;
  loading: boolean;
  id: string | null;
  name: string;
  options: string[];
}

export interface HomeState {
  tableForm: TableForm;
  votingSystemModal: VotingSystemModal;
  votingSystems: {
    owned: VotingSystem[];
    adminOwned: VotingSystem[];
  };
  allOwnedTables: Table[];
  ownedTableDetailsLoading: boolean;
  votingSystemsLoading: boolean;
}

const homeInitialState: HomeState = {
  tableForm: {
    open: false,
    selectedVotingSystem: null,
    name: '',
    loading: false,
    id: null,
  },
  votingSystemModal: {
    open: false,
    loading: false,
    id: null,
    name: '',
    options: ['', ''],
  },
  votingSystems: {
    owned: [],
    adminOwned: [],
  },
  allOwnedTables: [],
  ownedTableDetailsLoading: false,
  votingSystemsLoading: false,
};

const homeSlice = createSlice({
  name: 'home',
  initialState: homeInitialState,
  reducers: {
    setTableFormOpen(state, { payload }: PayloadAction<boolean>) {
      state.tableForm.open = payload;
    },
    setTableFormLoading(state, { payload }: PayloadAction<boolean>) {
      state.tableForm.loading = payload;
    },
    setTableFormName(state, { payload }: PayloadAction<string>) {
      state.tableForm.name = payload;
    },
    setTableFormSelectedVotingSystem(
      state,
      { payload }: PayloadAction<string | null>
    ) {
      state.tableForm.selectedVotingSystem = payload;
    },
    requestCreateTable(state, action: PayloadAction<CreateTableDto>) {},
    requestUpdateTable(state, action: PayloadAction<UpdateTableDto>) {},
    requestVotingSystems() {},
    requestOwnedTableDetails() {},
    setVotingSystems(
      state,
      { payload }: PayloadAction<GetOwnedVotingSystemsResponse>
    ) {
      state.votingSystems = payload;
    },
    setOwnedTableDetailsLoading(state, { payload }: PayloadAction<boolean>) {
      state.ownedTableDetailsLoading = payload;
    },
    setOwnedTableDetails(state, { payload }: PayloadAction<Table[]>) {
      state.allOwnedTables = payload;
    },
    setVotingSystemModalOpen(
      state,
      { payload }: PayloadAction<SetVotingSystemModalOpenPayload>
    ) {
      state.votingSystemModal.open = payload.open;
      if (payload.id) state.votingSystemModal.id = payload.id;
      else state.votingSystemModal.id = '';
    },
    setVotingSystemModalName(state, { payload }: PayloadAction<string>) {
      state.votingSystemModal.name = payload;
    },
    setVotingSystemModalOption(
      state,
      { payload }: PayloadAction<SetVotingSystemOption>
    ) {
      state.votingSystemModal.options[payload.index] = payload.value;
    },
    setVotingSystemModalAddOption(state) {
      state.votingSystemModal.options.push('');
    },
    setVotingSystemModalDeleteOption(
      state,
      { payload }: PayloadAction<number>
    ) {
      state.votingSystemModal.options.splice(payload, 1);
    },
    resetVotingSystemModalFormValues(state) {
      state.votingSystemModal = homeInitialState.votingSystemModal;
    },
    resetTableFormValues(state) {
      state.tableForm = homeInitialState.tableForm;
    },
    requestCreateVotingSystem() {},
    setVotingSystemModalLoading(state, { payload }: PayloadAction<boolean>) {
      state.votingSystemModal.loading = payload;
    },
    setVotingSystemModalEdit(
      state,
      { payload }: PayloadAction<Partial<VotingSystemModal>>
    ) {
      state.votingSystemModal = { ...state.votingSystemModal, ...payload };
    },
    setTableFormEdit(state, { payload }: PayloadAction<Partial<TableForm>>) {
      state.tableForm = { ...state.tableForm, ...payload };
    },
    requestEditVotingSystem(
      state,
      action: PayloadAction<UpdateVotingSystemDto>
    ) {},
    requestDeleteVotingSystem(state, action: PayloadAction<string>) {},
    requestDeleteTable(state, action: PayloadAction<string>) {},
  },
  extraReducers: {
    [authActions.logout().type]: () => {
      return homeInitialState;
    },
  },
});

export const homeActions = homeSlice.actions;

const homeReducer = homeSlice.reducer;

export default homeReducer;
