import { call, put, takeLatest } from 'redux-saga/effects';
import { ApiClient } from '../../global/apiClient';
import { PayloadAction } from '@reduxjs/toolkit';
import { resetPasswordActions } from './slice';
import {
  notificationActions,
  NotificationVariantEnum,
} from '../../components/Notification/slice';
import {
  LoginResponse,
  UnauthenticatedChangePasswordDTO,
} from '../../generated/api-client';
import { authActions } from '../Login/slice';
import { AxiosResponse } from 'axios';

export function* resetPasswordSaga({
  payload,
}: PayloadAction<UnauthenticatedChangePasswordDTO>) {
  try {
    const res: AxiosResponse<LoginResponse | void> = yield call(
      ApiClient.auth.unauthenticatedChangePassword,
      payload
    );

    const { data } = res;

    if (data) {
      yield put(
        authActions.setAuth({
          email: data.user.email,
          token: data.token,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          _id: data.user._id,
          plan: data.user.plan,
        })
      );
      yield put(resetPasswordActions.requestResetPasswordSuccess());
    } else {
      yield put(
        notificationActions.setNotification({
          variant: NotificationVariantEnum.Error,
          message: 'There was an error with the forgot password request',
        })
      );
      yield put(resetPasswordActions.requestResetPasswordFailure());
    }
  } catch (e) {
    yield put(
      notificationActions.setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error with the forgot password request',
      })
    );
    yield put(resetPasswordActions.requestResetPasswordFailure());
  }
}

export function* resetPasswordSagaWatcher() {
  yield takeLatest(
    resetPasswordActions.requestResetPassword,
    resetPasswordSaga
  );
}

export default resetPasswordSagaWatcher;
