import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SignupDto } from '../../generated/api-client';

interface SignupState {
  loading: boolean;
}

const initialState = { loading: false } as SignupState;

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    requestSignup(state, action: PayloadAction<SignupDto>) {
      return state;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const signupActions = signupSlice.actions;

const signupReducer = signupSlice.reducer;

export default signupReducer;
