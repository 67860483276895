import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ApiClient } from '../../global/apiClient';
import { LoginDto, LoginResponse } from '../../generated/api-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { authActions } from './slice';
import {
  notificationActions,
  NotificationVariantEnum,
} from '../../components/Notification/slice';

export function* requestLogin({ payload }: PayloadAction<LoginDto>) {
  try {
    yield put(authActions.setAuthLoading(true));
    const res: AxiosResponse<LoginResponse> = yield call(
      ApiClient.auth.login,
      payload
    );

    const { data } = res;

    yield put(
      authActions.setAuth({
        email: data.user.email,
        token: data.token,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        _id: data.user._id,
        plan: data.user.plan,
      })
    );
    yield put(authActions.resetGuestUser());
  } catch (e) {
    yield put(
      notificationActions.setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error with the login request',
      })
    );
  } finally {
    yield put(authActions.setAuthLoading(false));
  }
}

export function* authSagaWatcher() {
  yield takeLatest(authActions.requestLogin, requestLogin);
}

export default authSagaWatcher;
