import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles_LayoutWrapper = makeStyles(() =>
  createStyles({
    container: {
      marginTop: 68,
    },
  })
);
