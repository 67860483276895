import { call, put, takeLatest } from 'redux-saga/effects';
import { ApiClient } from '../../global/apiClient';
import { PayloadAction } from '@reduxjs/toolkit';
import { forgotPasswordActions } from './slice';
import {
  notificationActions,
  NotificationVariantEnum,
} from '../../components/Notification/slice';
import { ForgotPasswordDto } from '../../generated/api-client/model/forgot-password-dto';
import customHistory from '../../init/history';
import { PageRoutes } from '../../global/types';

export function* requestForgotPasswordSaga({
  payload,
}: PayloadAction<ForgotPasswordDto>) {
  try {
    yield call(ApiClient.auth.forgotPassword, payload);

    yield customHistory.push(PageRoutes.ResetPassword, {
      email: payload.email,
    });
    yield put(forgotPasswordActions.requestForgotPasswordSuccess());
  } catch (e) {
    yield put(forgotPasswordActions.requestForgotPasswordFailure());
    yield put(
      notificationActions.setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error with the forgot password request',
      })
    );
  }
}

export function* forgotPasswordSagaWatcher() {
  yield takeLatest(
    forgotPasswordActions.requestForgotPassword,
    requestForgotPasswordSaga
  );
}

export default forgotPasswordSagaWatcher;
