import { PaletteMode } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

export interface AppState {
  themeMode: PaletteMode;
}

const appInitialState: AppState = {
  themeMode: "light",
};

const appSlice = createSlice({
  name: "app",
  initialState: appInitialState,
  reducers: {
    toggleThemeMode(state) {
      if (state.themeMode === "dark") state.themeMode = "light";
      else if (state.themeMode === "light") state.themeMode = "dark";
    },
  },
});

export const appActions = appSlice.actions;

const appReducer = appSlice.reducer;

export default appReducer;
