import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Auth from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import Home from './pages/Home';
import RestrictedRoute from './components/RestrictedRoute';
import Table from './pages/Table';
import LayoutWrapper from './components/Layout/LayoutWrapper';
import RouteChangeTrackerHOC from './init/RouteChangeTrackerHOC';
import SignupPage from './pages/Signup';
import axios from 'axios';
import { useSelector } from 'react-redux';
import authSelectors from './pages/Login/selectors';
import { CssBaseline } from '@mui/material';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import appSelectors from './selectors';
import { PageRoutes } from './global/types';
import Profile from './pages/Profile';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';
import { createBrowserHistory } from 'history';
import { CustomRouter } from './init/CustomRouter';
import customHistory from './init/history';

const history = createBrowserHistory({});

function App() {
  const themeMode = useSelector(appSelectors.selectThemeMode);
  const token = useSelector(authSelectors.selectToken);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
        },
      }),
    [themeMode]
  );

  axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/v1/`;
  axios.defaults.headers.common['Authorization'] =
    token.length === 0 ? '' : `Bearer ${token}`;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CustomRouter history={customHistory}>
          <RouteChangeTrackerHOC history={history}>
            <LayoutWrapper>
              <Routes>
                <Route
                  path={PageRoutes.Auth}
                  element={<RestrictedRoute Component={Auth} />}
                />
                <Route
                  path={PageRoutes.ForgotPassword}
                  element={<RestrictedRoute Component={ForgotPasswordPage} />}
                />
                <Route
                  path={PageRoutes.ResetPassword}
                  element={<RestrictedRoute Component={ResetPasswordPage} />}
                />
                <Route
                  path={PageRoutes.Signup}
                  element={<RestrictedRoute Component={SignupPage} />}
                />
                <Route
                  path={PageRoutes.Home}
                  element={<PrivateRoute Component={Home} />}
                />
                <Route
                  path={`/${PageRoutes.Table}`}
                  element={<PrivateRoute Component={Home} />}
                />
                <Route
                  path={PageRoutes.Profile}
                  element={<PrivateRoute Component={Profile} />}
                />
                <Route
                  element={<Table />}
                  path={`/${PageRoutes.Table}/:slug`}
                />
              </Routes>
            </LayoutWrapper>
          </RouteChangeTrackerHOC>
        </CustomRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
