import makeStyles from '@mui/styles/makeStyles';

export const useTableStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(10),
  },
  tableContainer: {
    height: 120,
  },
  table: {
    height: "100%",
    width: "100%",
    backgroundColor: "rgb(215, 233, 255)",
    borderRadius: 25,
  },
  tableInnerContainer: {
    height: "100%",
    width: "100%",
  },
  cardInnerContainer: {
    height: "100%",
  },
  userContainer: {
    height: 80,
  },
  userCard: {
    height: 56,
    width: 32,
    backgroundColor: "#e8e9ea",
    borderRadius: 10,
  },
  votesShownUserCard: {
    height: 56,
    width: 32,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontWeight: 700,
    color: "white",
  },
  activeUserCard: {
    height: 56,
    width: 32,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
  },
  cardRowItemContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  hiddenUserCard: {
    height: 56,
    width: 32,
    backgroundColor: "inherit",
    borderRadius: 10,
  },
  userNameContainer: {
    textAlign: "center",
  },
  hiddenUserCardContainer: {
    color: "white",
  },
  row: {
    height: 105,
  },
  lastRow: {
    height: 105,
    marginTop: theme.spacing(2),
  },
  innerRow: {
    height: "100%",
  },
  cardRowContainer: {
    position: "fixed",
    bottom: 20,
    width: "100%",
  },
  votingSystemCard: {
    height: 56,
    width: 32,
    borderRadius: 10,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: 2,
    fontWeight: 700,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      cursor: "pointer",
      color: "white",
    },
  },
  activeVotingSystemCard: {
    height: 56,
    width: 32,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    fontWeight: 700,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      cursor: "pointer",
      color: "white",
      "> div > div": {},
    },
  },
}));
