/* tslint:disable */
/* eslint-disable */
/**
 * API
 * The API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddTableUserDto } from '../model';
// @ts-ignore
import { CreateTableDto } from '../model';
// @ts-ignore
import { RemoveTableUserDto } from '../model';
// @ts-ignore
import { Table } from '../model';
// @ts-ignore
import { UpdateTableDto } from '../model';
// @ts-ignore
import { UpdateTableUserVoteDto } from '../model';
/**
 * TableApi - axios parameter creator
 * @export
 */
export const TableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/table/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddTableUserDto} addTableUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser: async (addTableUserDto: AddTableUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addTableUserDto' is not null or undefined
            assertParamExists('addUser', 'addTableUserDto', addTableUserDto)
            const localVarPath = `/api/table/add-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTableUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTableDto} createTableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createTableDto: CreateTableDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTableDto' is not null or undefined
            assertParamExists('create', 'createTableDto', createTableDto)
            const localVarPath = `/api/table/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTableDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flipCards: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('flipCards', 'id', id)
            const localVarPath = `/api/table/flip-cards/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOne', 'id', id)
            const localVarPath = `/api/table/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwned: async (ownerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerId' is not null or undefined
            assertParamExists('getOwned', 'ownerId', ownerId)
            const localVarPath = `/api/table/owned/{ownerId}`
                .replace(`{${"ownerId"}}`, encodeURIComponent(String(ownerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoveTableUserDto} removeTableUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser: async (removeTableUserDto: RemoveTableUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'removeTableUserDto' is not null or undefined
            assertParamExists('removeUser', 'removeTableUserDto', removeTableUserDto)
            const localVarPath = `/api/table/remove-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeTableUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetActiveUsers: async (tableId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('resetActiveUsers', 'tableId', tableId)
            const localVarPath = `/api/table/reset-active-users/{tableId}`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startNewVote: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startNewVote', 'id', id)
            const localVarPath = `/api/table/start-new-vote/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTableDto} updateTableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (updateTableDto: UpdateTableDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTableDto' is not null or undefined
            assertParamExists('update', 'updateTableDto', updateTableDto)
            const localVarPath = `/api/table`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTableDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTableUserVoteDto} updateTableUserVoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserVote: async (updateTableUserVoteDto: UpdateTableUserVoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTableUserVoteDto' is not null or undefined
            assertParamExists('updateUserVote', 'updateTableUserVoteDto', updateTableUserVoteDto)
            const localVarPath = `/api/table/update-user-vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTableUserVoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TableApi - functional programming interface
 * @export
 */
export const TableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddTableUserDto} addTableUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUser(addTableUserDto: AddTableUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Table>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUser(addTableUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateTableDto} createTableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createTableDto: CreateTableDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createTableDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flipCards(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Table>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flipCards(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Table>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwned(ownerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwned(ownerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RemoveTableUserDto} removeTableUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUser(removeTableUserDto: RemoveTableUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Table>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUser(removeTableUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetActiveUsers(tableId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Table>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetActiveUsers(tableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startNewVote(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Table>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startNewVote(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTableDto} updateTableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(updateTableDto: UpdateTableDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Table>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(updateTableDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTableUserVoteDto} updateTableUserVoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserVote(updateTableUserVoteDto: UpdateTableUserVoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Table>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserVote(updateTableUserVoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TableApi - factory interface
 * @export
 */
export const TableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TableApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddTableUserDto} addTableUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(addTableUserDto: AddTableUserDto, options?: any): AxiosPromise<Table> {
            return localVarFp.addUser(addTableUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateTableDto} createTableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createTableDto: CreateTableDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.create(createTableDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flipCards(id: string, options?: any): AxiosPromise<Table> {
            return localVarFp.flipCards(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(id: string, options?: any): AxiosPromise<Table> {
            return localVarFp.getOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwned(ownerId: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getOwned(ownerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoveTableUserDto} removeTableUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser(removeTableUserDto: RemoveTableUserDto, options?: any): AxiosPromise<Table> {
            return localVarFp.removeUser(removeTableUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetActiveUsers(tableId: string, options?: any): AxiosPromise<Table> {
            return localVarFp.resetActiveUsers(tableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startNewVote(id: string, options?: any): AxiosPromise<Table> {
            return localVarFp.startNewVote(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTableDto} updateTableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(updateTableDto: UpdateTableDto, options?: any): AxiosPromise<Table> {
            return localVarFp.update(updateTableDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTableUserVoteDto} updateTableUserVoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserVote(updateTableUserVoteDto: UpdateTableUserVoteDto, options?: any): AxiosPromise<Table> {
            return localVarFp.updateUserVote(updateTableUserVoteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in TableApi.
 * @export
 * @interface TableApiDeleteRequest
 */
export interface TableApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TableApiDelete
     */
    readonly id: string
}

/**
 * Request parameters for addUser operation in TableApi.
 * @export
 * @interface TableApiAddUserRequest
 */
export interface TableApiAddUserRequest {
    /**
     * 
     * @type {AddTableUserDto}
     * @memberof TableApiAddUser
     */
    readonly addTableUserDto: AddTableUserDto
}

/**
 * Request parameters for create operation in TableApi.
 * @export
 * @interface TableApiCreateRequest
 */
export interface TableApiCreateRequest {
    /**
     * 
     * @type {CreateTableDto}
     * @memberof TableApiCreate
     */
    readonly createTableDto: CreateTableDto
}

/**
 * Request parameters for flipCards operation in TableApi.
 * @export
 * @interface TableApiFlipCardsRequest
 */
export interface TableApiFlipCardsRequest {
    /**
     * 
     * @type {string}
     * @memberof TableApiFlipCards
     */
    readonly id: string
}

/**
 * Request parameters for getOne operation in TableApi.
 * @export
 * @interface TableApiGetOneRequest
 */
export interface TableApiGetOneRequest {
    /**
     * 
     * @type {string}
     * @memberof TableApiGetOne
     */
    readonly id: string
}

/**
 * Request parameters for getOwned operation in TableApi.
 * @export
 * @interface TableApiGetOwnedRequest
 */
export interface TableApiGetOwnedRequest {
    /**
     * 
     * @type {string}
     * @memberof TableApiGetOwned
     */
    readonly ownerId: string
}

/**
 * Request parameters for removeUser operation in TableApi.
 * @export
 * @interface TableApiRemoveUserRequest
 */
export interface TableApiRemoveUserRequest {
    /**
     * 
     * @type {RemoveTableUserDto}
     * @memberof TableApiRemoveUser
     */
    readonly removeTableUserDto: RemoveTableUserDto
}

/**
 * Request parameters for resetActiveUsers operation in TableApi.
 * @export
 * @interface TableApiResetActiveUsersRequest
 */
export interface TableApiResetActiveUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof TableApiResetActiveUsers
     */
    readonly tableId: string
}

/**
 * Request parameters for startNewVote operation in TableApi.
 * @export
 * @interface TableApiStartNewVoteRequest
 */
export interface TableApiStartNewVoteRequest {
    /**
     * 
     * @type {string}
     * @memberof TableApiStartNewVote
     */
    readonly id: string
}

/**
 * Request parameters for update operation in TableApi.
 * @export
 * @interface TableApiUpdateRequest
 */
export interface TableApiUpdateRequest {
    /**
     * 
     * @type {UpdateTableDto}
     * @memberof TableApiUpdate
     */
    readonly updateTableDto: UpdateTableDto
}

/**
 * Request parameters for updateUserVote operation in TableApi.
 * @export
 * @interface TableApiUpdateUserVoteRequest
 */
export interface TableApiUpdateUserVoteRequest {
    /**
     * 
     * @type {UpdateTableUserVoteDto}
     * @memberof TableApiUpdateUserVote
     */
    readonly updateTableUserVoteDto: UpdateTableUserVoteDto
}

/**
 * TableApi - object-oriented interface
 * @export
 * @class TableApi
 * @extends {BaseAPI}
 */
export class TableApi extends BaseAPI {
    /**
     * 
     * @param {TableApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public _delete(requestParameters: TableApiDeleteRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TableApiAddUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public addUser(requestParameters: TableApiAddUserRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).addUser(requestParameters.addTableUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TableApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public create(requestParameters: TableApiCreateRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).create(requestParameters.createTableDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TableApiFlipCardsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public flipCards(requestParameters: TableApiFlipCardsRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).flipCards(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TableApiGetOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public getOne(requestParameters: TableApiGetOneRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).getOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TableApiGetOwnedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public getOwned(requestParameters: TableApiGetOwnedRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).getOwned(requestParameters.ownerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TableApiRemoveUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public removeUser(requestParameters: TableApiRemoveUserRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).removeUser(requestParameters.removeTableUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TableApiResetActiveUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public resetActiveUsers(requestParameters: TableApiResetActiveUsersRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).resetActiveUsers(requestParameters.tableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TableApiStartNewVoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public startNewVote(requestParameters: TableApiStartNewVoteRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).startNewVote(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TableApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public update(requestParameters: TableApiUpdateRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).update(requestParameters.updateTableDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TableApiUpdateUserVoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public updateUserVote(requestParameters: TableApiUpdateUserVoteRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).updateUserVote(requestParameters.updateTableUserVoteDto, options).then((request) => request(this.axios, this.basePath));
    }
}
