import React from 'react';
import { Button, TextField } from '@mui/material';
import { FormikProps, useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
import { useResetPasswordStyles } from './styles';

export interface ResetPasswordFormValues {
  email: string;
  confirmationCode: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface ResetPasswordFormProps {
  form: FormikProps<ResetPasswordFormValues>;
}

export const ResetPasswordForm = ({
  form: { values, errors, handleChange, handleBlur, handleSubmit },
}: ResetPasswordFormProps) => {
  const location = useLocation();
  const formik = useFormikContext();
  const styles = useResetPasswordStyles();

  React.useEffect(() => {
    if (location.state.email) {
      console.log(formik);
      formik.setFieldValue('email', location.state.email);
    }
  }, []);

  return (
    <form className={styles.form}>
      <TextField
        value={values.email}
        placeholder="Email"
        name="email"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        className={styles.input}
        error={!!errors.email}
        helperText={errors.email || ''}
        type="email"
      />
      <TextField
        value={values.confirmationCode}
        placeholder="Confirmation code"
        name="confirmationCode"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        className={styles.input}
        error={!!errors.confirmationCode}
        helperText={errors.confirmationCode || ''}
        type="number"
      />
      <TextField
        value={values.newPassword}
        placeholder="New password"
        name="newPassword"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        className={styles.input}
        error={!!errors.newPassword}
        helperText={errors.newPassword || ''}
        type="password"
      />
      <TextField
        value={values.confirmNewPassword}
        placeholder="Confirm new password"
        name="confirmNewPassword"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        className={styles.input}
        error={!!errors.confirmNewPassword}
        helperText={errors.confirmNewPassword || ''}
        type="password"
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => handleSubmit()}
      >
        Reset
      </Button>
    </form>
  );
};
