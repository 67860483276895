import {
  CircularProgress,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import _ from "lodash";
import { useTableStyles } from "./styles";
import { TableData, TableHeaderData } from "./types";
import { v4 } from "uuid";

interface MainTableProps {
  tableData: TableData | null;
  loading: boolean;
  tableHeaderData: TableHeaderData;
  noRowsMessage: string;
}

export default function MainTable({
  tableData,
  loading,
  tableHeaderData,
  noRowsMessage,
}: MainTableProps) {
  const classes = useTableStyles();

  return (
    <TableContainer component={Paper} className={classes.table}>
      <MuiTable>
        <TableHead>
          <TableRow>
            {tableHeaderData?.headerCells?.map((cell) => (
              <TableCell className={classes.columnHeader} key={v4()}>
                {cell.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell
                colSpan={4}
                style={{
                  textAlign: "center",
                }}
              >
                <CircularProgress color="secondary" />
              </TableCell>
            </TableRow>
          ) : !_.isEmpty(tableData) ? (
            tableData?.rows?.map((row) => (
              <TableRow key={v4()}>
                {row?.cells?.map((cell) => (
                  <React.Fragment key={v4()}>
                    <TableCell key={cell?._id}>{cell.component}</TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={4}
                style={{
                  textAlign: "center",
                }}
              >
                {noRowsMessage}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}
