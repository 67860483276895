import React from "react";
import { Button, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ReactGA from "react-ga";
import { useAppDispatch } from "../../../init/hooks";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import tableSelectors from "../selectors";
import {
  notificationActions,
  NotificationVariantEnum,
} from "../../../components/Notification/slice";

const useStyles = makeStyles((theme: Theme) => ({
  anchorButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function CopyLink() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const tableId = useSelector(tableSelectors.selectTableId);

  const handleClick = () => {
    navigator.clipboard.writeText(window.location.href);
    dispatch(
      notificationActions.setNotification({
        variant: NotificationVariantEnum.Success,
        message: "Link copied",
      })
    );
    ReactGA.event({
      category: "Table",
      action: "Link copied",
    });
  };
  return (
    <React.Fragment>
      {location.pathname === `/table/${tableId}` && (
        <Button
          variant="text"
          color="inherit"
          className={classes.anchorButton}
          onClick={handleClick}
        >
          Copy Link
        </Button>
      )}
    </React.Fragment>
  );
}
