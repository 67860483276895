import { createSelector } from 'reselect';
import { RootState } from '../../global/redux/store';

const selectResetPassword = (state: RootState) => state.resetPassword;

const resetPasswordSelectors = {
  loading: createSelector(selectResetPassword, (state) => state.loading),
};

export default resetPasswordSelectors;
