import { io, Socket } from 'socket.io-client';
import { eventChannel, EventChannel } from 'redux-saga';
import { call, take, all, race, put } from 'redux-saga/effects';
import { SocketActionTypes } from './actions';
import { tableActions } from '../../../pages/Table/slice';
import { Table } from '../../../generated/api-client';

export declare const process: {
  env: {
    REACT_APP_API_URL: string;
  };
};
let socket: Socket;

const createEventChannel = (tableId: string) => {
  return eventChannel((emitter) => {
    socket = io(process.env.REACT_APP_API_URL, {
      path: '/socket.io',
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      socket.emit('registerToRoom', tableId, () => {
        // TODO BG - need to fix when a user connects to the socket and when they disconnect. Right now it connects on every screen
        console.log('testing');
      });
    });

    /** The updated table sent from the socket connection. Socket is not set to scale on the BE so the fromSocket action filters to make sure it only updates when the id matches */
    socket.on('updatedTable', (payload: Table) => {
      return emitter(tableActions.setTableDataFromSocket(payload));
    });

    socket.on('disconnect', () => {});
    return () => {
      // do whatever to interrupt the socket communication here
    };
  });
};

function* initializeWebSocketsChannel() {
  console.log('going to connect to socket');
  const length = window.location.href.split('/').length;
  const tableId = window.location.href.split('/')[length - 1];
  const channel: EventChannel<unknown> = yield call(
    createEventChannel,
    tableId
  );
  while (true) {
    const { payload } = yield take(channel);
    yield put(tableActions.setTableDataFromSocket(payload));
  }
}

export function* startStopChannel() {
  while (true) {
    yield race({
      task: call(initializeWebSocketsChannel),
      cancel: take(SocketActionTypes.StopChannel),
    });
    //if cancel wins the race we can close socket
    socket.close();
  }
}

export default function* rootSaga() {
  yield all([startStopChannel()]);
}
