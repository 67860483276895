import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../global/redux/store';
import { homeActions, HomeState } from './slice';
import { ApiClient } from '../../global/apiClient';
import {
  CreateTableDto,
  CreateVotingSystemDto,
  Table,
  UpdateTableDto,
  UpdateVotingSystemDto,
} from '../../generated/api-client';
import {
  notificationActions,
  NotificationVariantEnum,
} from '../../components/Notification/slice';
import { GetOwnedVotingSystemsResponse } from '../../generated/api-client/model/get-owned-voting-systems-response';

const { setNotification } = notificationActions;

export function* getOwnedVotingSystemsSaga() {
  try {
    const authData: RootState['auth'] = yield select((state) => state.auth);

    const res: AxiosResponse<GetOwnedVotingSystemsResponse> = yield call(
      ApiClient.votingSystem.getOwned,
      authData.facilitator._id
    );

    const { data } = res;

    yield put(homeActions.setVotingSystems(data));
  } catch (e) {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error getting your voting systems',
      })
    );
  }
}

export function* createTableSaga({ payload }: PayloadAction<CreateTableDto>) {
  try {
    yield put(homeActions.setTableFormLoading(true));

    yield call(ApiClient.table.create, payload);
    yield put(homeActions.requestOwnedTableDetails());
    yield put(homeActions.resetTableFormValues());
  } catch (e) {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error',
      })
    );
  } finally {
    yield put(homeActions.setTableFormLoading(false));
  }
}

export function* getOwnedTableDetailsSaga() {
  try {
    yield put(homeActions.setOwnedTableDetailsLoading(true));
    const id: string = yield select(
      (state: RootState) => state.auth.facilitator._id
    );

    const res: AxiosResponse<Table[]> = yield call(
      ApiClient.table.getOwned,
      id
    );

    const { data } = res;

    yield put(homeActions.setOwnedTableDetails(data));
  } catch (e) {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error getting your owned tables',
      })
    );
  } finally {
    yield put(homeActions.setOwnedTableDetailsLoading(false));
  }
}

export function* createVotingSystemSaga() {
  try {
    yield put(homeActions.setVotingSystemModalLoading(true));

    const auth: RootState['auth'] = yield select((state) => state.auth);
    const home: HomeState = yield select((state) => state.home);

    const createVotingSystemForm = home.votingSystemModal;

    const reqBody: CreateVotingSystemDto = {
      owner: auth.facilitator._id,
      name: createVotingSystemForm.name,
      options: createVotingSystemForm.options,
    };

    yield call(ApiClient.votingSystem.create, reqBody);

    yield put(
      setNotification({
        variant: NotificationVariantEnum.Success,
        message: 'Voting system created',
      })
    );
    yield put(homeActions.resetVotingSystemModalFormValues());
    yield put(homeActions.requestVotingSystems());
  } catch {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error creating your voting system',
      })
    );
    yield put(homeActions.setVotingSystemModalLoading(false));
  }
}

export function* editVotingSystemSaga({
  payload,
}: PayloadAction<UpdateVotingSystemDto>) {
  try {
    yield put(homeActions.setVotingSystemModalLoading(true));

    yield call(ApiClient.votingSystem.update, payload);

    yield put(
      setNotification({
        variant: NotificationVariantEnum.Success,
        message: 'Voting system edited',
      })
    );
    yield put(homeActions.resetVotingSystemModalFormValues());
    yield put(homeActions.requestVotingSystems());
  } catch {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error',
      })
    );
    yield put(homeActions.setVotingSystemModalLoading(false));
  }
}

export function* updateTableSaga({ payload }: PayloadAction<UpdateTableDto>) {
  try {
    yield put(homeActions.setTableFormLoading(true));

    yield call(ApiClient.table.update, payload);

    yield put(
      setNotification({
        variant: NotificationVariantEnum.Success,
        message: 'Table created',
      })
    );
    yield put(homeActions.resetTableFormValues());
    yield put(homeActions.requestOwnedTableDetails());
  } catch {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error updating your table',
      })
    );
    yield put(homeActions.setTableFormLoading(false));
  }
}

export function* deleteVotingSystemSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(homeActions.setVotingSystemModalLoading(true));

    yield call(ApiClient.votingSystem._delete, payload);

    yield put(
      setNotification({
        variant: NotificationVariantEnum.Success,
        message: 'Voting system deleted',
      })
    );
    yield put(homeActions.resetVotingSystemModalFormValues());
    yield put(homeActions.requestVotingSystems());
  } catch {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error',
      })
    );
    yield put(homeActions.setVotingSystemModalLoading(false));
  }
}

export function* deleteTableSaga({ payload }: PayloadAction<string>) {
  try {
    yield put(homeActions.setOwnedTableDetailsLoading(true));

    yield call(ApiClient.table._delete, payload);

    yield put(
      setNotification({
        variant: NotificationVariantEnum.Success,
        message: 'Table deleted',
      })
    );
    yield put(homeActions.resetTableFormValues());
    yield put(homeActions.requestOwnedTableDetails());
  } catch {
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error deleting this table',
      })
    );
    yield put(homeActions.setOwnedTableDetailsLoading(false));
  }
}

export function* homeSagaWatcher() {
  yield takeLatest(homeActions.requestVotingSystems, getOwnedVotingSystemsSaga);
  yield takeLatest(homeActions.requestCreateTable, createTableSaga);
  yield takeLatest(
    homeActions.requestOwnedTableDetails,
    getOwnedTableDetailsSaga
  );
  yield takeLatest(
    homeActions.requestCreateVotingSystem,
    createVotingSystemSaga
  );
  yield takeLatest(homeActions.requestEditVotingSystem, editVotingSystemSaga);
  yield takeLatest(homeActions.requestUpdateTable, updateTableSaga);
  yield takeLatest(
    homeActions.requestDeleteVotingSystem,
    deleteVotingSystemSaga
  );
  yield takeLatest(homeActions.requestDeleteTable, deleteTableSaga);
}

export default homeSagaWatcher;
