import { makeStyles } from '@mui/styles';

export const useFacilitatorInformationSectionStyles = makeStyles((theme) => ({
  textField: {
    width: '96%',
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(10),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  avatar: {
    '& svg': {
      fill: '#fff',
    },
    '&:hover $avatarHover': {
      opacity: 1,
    },
    alignItems: 'center',
    borderRadius: '100%',
    display: 'grid',
    height: 120,
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    width: 120,
  },
  avatarActionText: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#fff',
    cursor: 'pointer',
    fontSize: 12,
  },
  avatarDefault: {
    '& p': {
      color: '#fff',
      fontSize: 35,
      fontWeight: 'bold',
      lineHeight: '120px',
    },
    background: theme.palette.primary.main,
    height: 120,
    textAlign: 'center',
    width: 120,
  },
  avatarHover: {
    background: '#00000080',
    borderRadius: '100%',
    fontSize: 12,
    fontWeight: 500,
    height: 120,
    opacity: 0,
    padding: theme.spacing(2.5, 0),
    position: 'absolute',
    textAlign: 'center',
    textTransform: 'uppercase',
    transition: 'opacity 0.5s',
    width: 120,
  },
  avatarImage: {
    pointerEvents: 'none',
    width: '100%',
  },
  fileField: {
    display: 'none',
  },
  prop: {
    marginBottom: theme.spacing(2),
  },
  propGrid: {
    display: 'grid',
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(1),
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  root: {
    display: 'grid',
    gridColumnGap: theme.spacing(4),
    gridTemplateColumns: '120px 1fr',
  },
  modalInput: {
    marginBottom: theme.spacing(2),
  },
}));
