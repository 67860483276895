// import { store } from "..";
import {
  AuthApiFactory,
  VotingSystemApiFactory,
  Configuration,
  TableApiFactory,
  UserApiFactory,
} from '../generated/api-client';
import { PlanApiFactory } from '../generated/api-client/api/plan-api';

export const ApiClientConfig = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
});

export const ApiClient = {
  auth: AuthApiFactory(ApiClientConfig),
  votingSystem: VotingSystemApiFactory(ApiClientConfig),
  table: TableApiFactory(ApiClientConfig),
  user: UserApiFactory(ApiClientConfig),
  plan: PlanApiFactory(ApiClientConfig),
};
