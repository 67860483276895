import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageRoutes } from '../../../../global/types';
import ReactGA from 'react-ga';
import MainTable from '../../../../components/Tables/MainTable';
import {
  CellData,
  RowData,
  TableData,
} from '../../../../components/Tables/MainTable/types';
import { useAppDispatch } from '../../../../init/hooks';
import { v4 } from 'uuid';
import { homeActions } from '../../slice';
import { IconButton, useTheme } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Table } from '../../../../generated/api-client';
import { useSelector } from 'react-redux';
import homeSelectors from '../../selectors';

export default function OwnedTablesWidget() {
  const dispatch = useAppDispatch();
  const [tableData, setTableData] = useState<TableData | null>(null);
  const theme = useTheme();
  const ownedTables = useSelector(homeSelectors.selectAllOwnedTables);
  const ownedTableDetailsLoading = useSelector(
    homeSelectors.selectOwnedTableDetailsLoading
  );

  useEffect(() => {
    dispatch(homeActions.requestOwnedTableDetails());
  }, []);

  useEffect(() => {
    if (ownedTables) {
      const temp: TableData = {
        rows: tableDetailsToRowArray(),
      };
      setTableData(temp);
    }
  }, [ownedTables]);

  const trackLinkClick = () => {
    ReactGA.event({
      category: 'Table',
      action: 'Table link clicked',
    });
  };

  const tableDetailsToRowArray = () => {
    const rows: RowData[] = [];
    ownedTables.forEach((table) => {
      rows.push({
        cells: tableDetailsToCellArray(table),
        _id: table._id,
      });
    });
    return rows;
  };

  const handleEditClick = ({ _id, tableName, votingSystem }: Table) => {
    dispatch(
      homeActions.setTableFormEdit({
        open: true,
        id: _id,
        name: tableName,
        selectedVotingSystem: votingSystem,
      })
    );
  };

  const tableDetailsToCellArray = (table: Table) => {
    const cells: CellData[] = [];
    cells.push({
      _id: v4(),
      component: (
        <Link
          to={`${PageRoutes.Table}/${table._id}`}
          target="_blank"
          onClick={trackLinkClick}
          style={{
            textDecoration: 'none',
            color: theme.palette.primary.main,
          }}
        >
          {table.tableName}
        </Link>
      ),
    });
    cells.push({
      _id: v4(),
      component: <>{table.activeUsers.length}</>,
    });
    cells.push({
      _id: v4(),
      component: (
        <IconButton onClick={() => handleEditClick(table)} size="large">
          <Edit fontSize="small" />
        </IconButton>
      ),
    });

    return cells;
  };

  const headerData = {
    headerCells: [{ name: 'Name' }, { name: 'Active Users' }, { name: '' }],
  };

  return (
    <MainTable
      noRowsMessage="You don't have any tables created yet"
      loading={ownedTableDetailsLoading}
      tableHeaderData={headerData}
      tableData={tableData}
    />
  );
}
