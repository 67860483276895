import React from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import homeSelectors from "../../../selectors";
import { homeActions } from "../../../slice";
import _ from "lodash";

export default function OptionsInput() {
  const options = useSelector(homeSelectors.selectVotingSystemFormOptions);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleDelete = (index: number) => {
    const tempOptions = [...options];
    tempOptions.splice(index, 1);

    dispatch(homeActions.setVotingSystemModalDeleteOption(index));
  };

  const handleOptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) => {
    if (e.target.value.length <= 4 && !e.target.value.includes(" ")) {
      const tempOptions = _.cloneDeep(options);
      tempOptions.splice(index, 1, e.target.value);
      dispatch(
        homeActions.setVotingSystemModalOption({
          index,
          value: e.target.value,
        })
      );
    }
  };

  return (
    <>
      {options.map((option, index) => (
        <Grid item xs={12}>
          <FormControl
            sx={{ marginTop: theme.spacing(1) }}
            variant="outlined"
            fullWidth
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {`Option ${index + 1}`}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              value={option}
              onChange={(e) => handleOptionChange(e, index)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleDelete(index)}
                    edge="end"
                  >
                    <Delete />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>
      ))}
    </>
  );
}
