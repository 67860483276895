import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddTableUserDtoTypeEnum,
  RemoveTableUserDto,
  Table,
  VotingSystem,
} from '../../generated/api-client';
import { GetTableOwnerDetailsResponse } from '../../generated/api-client/model/get-table-owner-details-response';
import { authActions } from '../Login/slice';

export interface RequestAddTableUserPayload {
  name: string;
  type: AddTableUserDtoTypeEnum;
}

export interface TableState {
  loading: {
    tableOwnerPlan: boolean;
    votingSystemDetails: boolean;
    getTable: boolean;
  };
  tableData: Table;
  tableOwnerDetails: {
    plan?: number;
  };
  votingSystemDetails: VotingSystem | null;
  leaveRequestPending: boolean;
  resetTableForm: {
    open: boolean;
  };
  enterTableForm: {
    name: string;
  };
  timer: {
    timerId?: NodeJS.Timer;
    playing: boolean;
    done: boolean;
    startTime: number;
    timer: number;
  };
}

const tableInitialState: TableState = {
  loading: {
    tableOwnerPlan: false,
    votingSystemDetails: false,
    getTable: false,
  },
  tableData: {
    votesShown: false,
    _id: '',
    owner: '',
    votingSystem: '',
    activeUsers: [],
    tableName: '',
  },
  tableOwnerDetails: {},
  votingSystemDetails: null,
  leaveRequestPending: false,
  resetTableForm: {
    open: false,
  },
  enterTableForm: {
    name: '',
  },
  timer: {
    timerId: undefined,
    playing: false,
    done: false,
    startTime: 60 * 5,
    timer: 60 * 5,
  },
};

const tableSlice = createSlice({
  name: 'table',
  initialState: tableInitialState,
  reducers: {
    setTableData(state, { payload }: PayloadAction<Table>) {
      state.tableData = payload;
    },
    requestGetTableSuccess(state, { payload }: PayloadAction<Table>) {
      state.tableData = payload;
      state.loading.getTable = false;
    },
    requestGetTableFailure(state) {
      state.loading.getTable = false;
    },
    requestVotingSystemDetailsSuccess(
      state,
      { payload }: PayloadAction<VotingSystem>
    ) {
      state.votingSystemDetails = payload;
      state.loading.votingSystemDetails = false;
    },
    requestVotingSystemDetailsFailure(state) {
      state.loading.votingSystemDetails = false;
    },
    setTableDataFromSocket(state, { payload }: PayloadAction<Table>) {
      if (state.tableData._id === payload._id) {
        state.tableData = payload;
      }
    },
    setLeaveRequestPending(state, { payload }: PayloadAction<boolean>) {
      state.leaveRequestPending = payload;
    },
    setResetTableOpen(state, { payload }: PayloadAction<boolean>) {
      state.resetTableForm.open = payload;
    },
    clearResetTableForm(state) {
      state.resetTableForm = tableInitialState.resetTableForm;
    },
    setGuestName(state, { payload }: PayloadAction<string>) {
      state.enterTableForm.name = payload;
    },
    timerStart(state, { payload }: PayloadAction<NodeJS.Timer>) {
      state.timer.playing = true;
      state.timer.done = false;
      state.timer.timerId = payload;
    },
    setTimerPlaying(state, { payload }: PayloadAction<boolean>) {
      state.timer.playing = payload;
    },
    setTimerDone(state, { payload }: PayloadAction<boolean>) {
      state.timer.done = payload;
    },
    setStartTime(state, { payload }: PayloadAction<number>) {
      state.timer.startTime = payload;
    },

    requestTableOwnerPlanSuccess(
      state,
      { payload }: PayloadAction<GetTableOwnerDetailsResponse>
    ) {
      state.tableOwnerDetails = payload;
      state.loading.tableOwnerPlan = false;
    },
    requestTableOwnerPlanFailure(state) {
      state.loading.tableOwnerPlan = false;
    },

    requestStartNewVote() {},
    requestLeaveTable() {},
    requestFlipCards() {},
    requestUpdateUserVote(state, action: PayloadAction<string | null>) {},
    requestVotingSystemDetails(state, action: PayloadAction<string>) {
      state.loading.votingSystemDetails = true;
    },
    requestAddTableUser(
      state,
      action: PayloadAction<RequestAddTableUserPayload>
    ) {},
    requestGetTable(state, action: PayloadAction<string>) {
      state.loading.getTable = true;
    },
    requestResetActiveUsers(state, action: PayloadAction<string>) {},
    requestRemoveUser(state, action: PayloadAction<RemoveTableUserDto>) {},
    requestTableOwnerPlan(state, action: PayloadAction<string>) {
      state.loading.tableOwnerPlan = true;
    },
  },
  extraReducers: {
    [authActions.logout().type]: () => {
      return tableInitialState;
    },
  },
});

export const tableActions = tableSlice.actions;

const tableReducer = tableSlice.reducer;

export default tableReducer;
