import makeStyles from '@mui/styles/makeStyles';

export const useTableStyles = makeStyles(() => ({
  table: {
    minHeight: 140,
  },
  columnHeader: {
    fontWeight: 700,
  },
}));
