import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router';
import { PageRoutes } from '../../../global/types';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useAppDispatch } from '../../../init/hooks';
import { authActions } from '../../../pages/Login/slice';
import CopyLink from '../../../pages/Table/components/CopyLink';
import authSelectors from '../../../pages/Login/selectors';
import { useSelector } from 'react-redux';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { appActions } from '../../../slice';
import appSelectors from '../../../selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      '& *': {
        textDecoration: 'none',
        color: 'white',
      },
    },
    headerCenterSpace: {},
  })
);

export default function Header() {
  const classes = useStyles();
  const isUserLoggedIn = useSelector(authSelectors.selectIsUserLoggedIn);
  const themeMode = useSelector(appSelectors.selectThemeMode);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    dispatch(authActions.logout());
    navigate(PageRoutes.Auth);
    ReactGA.event({
      category: 'User',
      action: 'Logout Attempt',
    });
  };

  return (
    <div className={classes.root}>
      <AppBar position="absolute">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link to={PageRoutes.Home}>Agile Poker</Link>
          </Typography>
          <CopyLink />
          <IconButton
            sx={{ ml: 1 }}
            onClick={() => dispatch(appActions.toggleThemeMode())}
            color="inherit"
          >
            {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          {isUserLoggedIn && (
            <>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                size="large"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigate(PageRoutes.Profile)}>
                  Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
