import { combineReducers } from 'redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { routerReducer } from 'react-router-redux';
import sagas from './sagas';
import signupReducer from '../../pages/Signup/slice';
import homeReducer from '../../pages/Home/slice';
import authReducer from '../../pages/Login/slice';
import tableReducer from '../../pages/Table/slice';
import notificationReducer from '../../components/Notification/slice';
import appReducer from '../../slice';
import profileReducer from '../../pages/Profile/slice';
import forgotPasswordReducer from '../../pages/ForgotPassword/slice';
import resetPasswordReducer from '../../pages/ResetPassword/slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  notification: notificationReducer,
  router: routerReducer,
  table: tableReducer,
  signup: signupReducer,
  app: appReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      sagaMiddleware,
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

sagas.map(sagaMiddleware.run);

export default configureStore;
