import { createSelector } from "reselect";
import { RootState } from "../../global/redux/store";

const selectHome = (state: RootState) => state.home;
const selectTableForm = (state: RootState) => state.home.tableForm;

const homeSelectors = {
  selectTableFormOpen: createSelector(
    selectHome,
    (state) => state.tableForm.open
  ),
  selectVotingSystems: createSelector(
    selectHome,
    (state) => state.votingSystems
  ),
  selectAllOwnedTables: createSelector(
    selectHome,
    (state) => state.allOwnedTables
  ),
  selectOwnedTableDetailsLoading: createSelector(
    selectHome,
    (state) => state.ownedTableDetailsLoading
  ),
  selectVotingSystemModalOpen: createSelector(
    selectHome,
    (state) => state.votingSystemModal.open
  ),
  selectVotingSystemModal: createSelector(
    selectHome,
    (state) => state.votingSystemModal
  ),
  selectVotingSystemsLoading: createSelector(
    selectHome,
    (state) => state.votingSystemsLoading
  ),
  selectVotingSystemFormOptions: createSelector(
    selectHome,
    (state) => state.votingSystemModal.options
  ),
  selectTableForm: createSelector(selectHome, (state) => state.tableForm),
  selectIsTableFormSubmitDisabled: createSelector(
    selectTableForm,
    (state) => state.selectedVotingSystem === null || state.name.length === 0
  ),
};

export default homeSelectors;
