import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { profileActions } from './slice';
import { ApiClient } from '../../global/apiClient';
import { UpdateUserDto, User } from '../../generated/api-client';
import {
  notificationActions,
  NotificationVariantEnum,
} from '../../components/Notification/slice';
import { Plan } from '../../generated/api-client/model/plan';
import { UpdateUserPlanDto } from '../../generated/api-client/model/update-user-plan-dto';
import { ChangePasswordDto } from '../../generated/api-client/model/change-password-dto';
import { authActions } from '../Login/slice';

const { setNotification } = notificationActions;

export function* saveProfileSaga({ payload }: PayloadAction<UpdateUserDto>) {
  try {
    const res: AxiosResponse<User> = yield call(
      ApiClient.user.updateUser,
      payload
    );

    const { data } = res;

    yield put(profileActions.saveProfileSuccess(data));
  } catch (e) {
    yield put(profileActions.saveProfileFailure());
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an issue with updating your profile',
      })
    );
  }
}

export function* getAllPlansSaga() {
  try {
    const res: AxiosResponse<Plan[]> = yield call(ApiClient.plan.getAll);

    const { data } = res;

    yield put(profileActions.getAllPlansSuccess(data));
  } catch (e) {
    yield put(profileActions.getAllPlansFailure());
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an issue with getting the list of plans',
      })
    );
  }
}

export function* updateUserPlanSaga({
  payload,
}: PayloadAction<UpdateUserPlanDto>) {
  try {
    yield call(ApiClient.user.updateUserPlan, payload);

    yield put(profileActions.updateUserPlanSuccess());
    yield put(authActions.setAuth({ plan: payload.plan }));
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Success,
        message: 'Plan updated successfully',
      })
    );
  } catch (e) {
    yield put(profileActions.updateUserPlanFailure());
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an issue with updating your plan',
      })
    );
  }
}

export function* changePasswordSaga({
  payload,
}: PayloadAction<ChangePasswordDto>) {
  try {
    const { data }: AxiosResponse<User | string> = yield call(
      ApiClient.auth.changePassword,
      payload
    );

    // If the response is a string, the API has returned an error
    if (typeof data === 'string') {
      yield put(profileActions.changePasswordFailure());
      yield put(
        setNotification({
          variant: NotificationVariantEnum.Error,
          message: data,
        })
      );
    } else {
      yield put(profileActions.changePasswordSuccess());
    }
  } catch (e) {
    yield put(profileActions.changePasswordFailure());
    yield put(
      setNotification({
        variant: NotificationVariantEnum.Error,
        message: 'There was an error with changing your password',
      })
    );
  }
}

export function* profileSagaWatcher() {
  yield takeLatest(profileActions.requestSaveProfile, saveProfileSaga);
  yield takeLatest(profileActions.requestGetAllPlans, getAllPlansSaga);
  yield takeLatest(profileActions.requestUpdateUserPlan, updateUserPlanSaga);
  yield takeLatest(profileActions.requestChangePassword, changePasswordSaga);
}

export default profileSagaWatcher;
