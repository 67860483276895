import { Button, Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import OwnedTablesWidget from './components/OwnedTablesWidget';
import CreateTableModal from './components/CreateTableModal';
import VotingSystemsWidget from './components/VotingSystemsWidget';
import CreateVotingSystemModal from './components/VotingSystemModal';
import { useAppDispatch } from '../../init/hooks';
import { homeActions } from './slice';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../global/enums';
import useUserPlan from '../../hooks/useUserPlan';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: 20,
    marginBottom: 20,
  },
  widgetContainer: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

export default function Home() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { hasCustomPointingScales } = useUserPlan();

  const handleCreateNewTableClick = () => {
    dispatch(homeActions.setTableFormOpen(true));
  };

  const handleCreateNewVotingSystemClick = () => {
    dispatch(homeActions.setVotingSystemModalOpen({ open: true }));
  };

  return (
    <Container>
      <Helmet>
        <title>{PageTitles.Home}</title>
      </Helmet>
      <Grid container justifyContent="center">
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateNewTableClick}
                className={classes.button}
              >
                Create New Table
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item>
              {hasCustomPointingScales && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateNewVotingSystemClick}
                  className={classes.button}
                >
                  Create New Voting System
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6} className={classes.widgetContainer}>
          <OwnedTablesWidget />
        </Grid>
        <Grid item xs={12} md={6} className={classes.widgetContainer}>
          <VotingSystemsWidget />
        </Grid>
      </Grid>
      <CreateTableModal />
      <CreateVotingSystemModal />
    </Container>
  );
}
