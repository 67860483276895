import { createSelector } from "reselect";
import { AddTableUserDtoTypeEnum } from "../../generated/api-client";
import { RootState } from "../../global/redux/store";

const selectAuth = (state: RootState) => state.auth;

const authSelectors = {
  selectGuestUser: createSelector(selectAuth, (state) => state.guestUser),
  selectIsUserLoggedIn: createSelector(
    selectAuth,
    (state) => state.facilitator.email.length > 0
  ),
  selectGuestUserExists: createSelector(
    selectAuth,
    (state) =>
      state.guestUser.browserId.length > 0 && state.guestUser.name.length > 0
  ),
  selectIsGuestUserSpectator: createSelector(
    selectAuth,
    (state) => state.guestUser.type === AddTableUserDtoTypeEnum.Spectator
  ),
  selectToken: createSelector(selectAuth, (state) => state.facilitator.token),
  selectFacilitator: createSelector(selectAuth, (state) => state.facilitator),
  selectFacilitatorId: createSelector(
    selectAuth,
    (state) => state.facilitator._id
  ),
  selectAuthLoading: createSelector(selectAuth, (state) => state.authLoading),
  selectGuestUserBrowserId: createSelector(
    selectAuth,
    (state) => state.guestUser.browserId
  ),
  selectGuestUserName: createSelector(
    selectAuth,
    (state) => state.guestUser.name
  ),
};

export default authSelectors;
